/*
    #-------------  Declaring Environment  -------------#
*/
export const Environment = {
    mainApi  : process.env.REACT_APP_MAIN_API,
    imageApi : process.env.REACT_APP_IMAGE_API,
    google:{
        apiKey:process.env.REACT_APP_DRIVE_API_KEY,
        clientId:process.env.REACT_APP_DRIVE_CLIENT_ID
    }
}

export const ENV = Environment;