import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"; 
import { isLoggedIn } from '../helpers/decode-token';
import { decodedToken } from '../helpers/decode-token';

// --- Public Routes --- //
import LandingPage from '../components/HomeComponent/LandingPage'
import Login from '../components/LoginComponent/Login_sso';
// import Login from '../components/LoginComponent/Login_sso_emailpass';
// import Signup from '../components/SignUpComponent/SignUp';
import NotFound from '../components/Errors/404';

// --- Protected Routes --- //
import HomeComponent from '../components/HomeComponent/HomeComponent';
import UploadScriptComponent from '../components/UploadScriptComponent/UploadScriptComponent';
import SearchScriptComponent  from '../components/SearchScriptComponent/SearchScriptComponent';
import BucketScriptComponent  from '../components/SearchScriptComponent/BucketScriptComponent';
import UserProfile from '../components/UserProfileComponent/UserProfile';
import VideoBucket from '../components/SearchScriptComponent/VideoBucketComponent';
import ReviewScript from '../components/UploadScriptComponent/ReviewScript';


import { toast } from "react-toastify";
toast.configure()

const ROUTES = [
    { path: '/', key:'LANDING_PAGE', exact: true, component:LandingPage, isLoggedIn: isLoggedIn},
    { path: '/login', key:'SIGN_IN', component:Login, isLoggedIn: isLoggedIn },
    { path: "/review/script/:scriptId" , key: 'REVIEW_SCRIPT', exact: true , component : ReviewScript},
    {
        path: "/auth",
        key: "Home",
        component: props => {
            if(!isLoggedIn){
                return <Redirect to="/login"/>
            }
            if(Array.isArray(decodedToken.role)){
              if(decodedToken.role.every(r => r === 'user' ) && props.location.pathname === '/auth/script/upload' ){
                  return <Redirect to = "/"/>
              }
            }else{
              if(decodedToken.role === 'user' && props.location.pathname === '/auth/script/upload' ){
                return <Redirect to = "/"/>
              }
            }  
            return <RenderRoutes {...props} />;
        },
        routes: [
            { path: "/auth", key: "APP_INDEX", exact: true, component:HomeComponent },
            { path: "/auth/script/upload", key: 'UPLOAD_SCRIPT', exact: true, component: UploadScriptComponent},
            { path: "/auth/script/search", key: 'SEARCH_SCRIPT', exact: true, component: SearchScriptComponent},
            { path: "/auth/script/bucket", key: 'BUCKET_SCRIPT', exact: true, component: BucketScriptComponent},
            { path: "/auth/user/profile", key: 'USER_PROFILE', exact: true, component: UserProfile},
            { path: "/auth/video-bucket", key: 'VIDEO_BUCKET', exact: true, component: VideoBucket},
            
            
            
            // { path: '*', key:"NO_MATCH", component: NotFound }
        ],
    },
];

export default ROUTES;

function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
}

export function RenderRoutes({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route render={props => <NotFound />} />
      </Switch>
    );
}