import React, { Component } from 'react';
import { Container, Row, Col, Modal, Card, Button, InputGroup, Form } from 'react-bootstrap';

class BucketScriptComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            openDownloadModal: false,
       }
    }
    render(){
        const { openDownloadModal } = this.state
        return(
            <>
                <div className="videoScript bucketScript px-md-5 pt-2 pb-5">
                    <Container fluid>
                        <Row className="justify-content-center mt-5">
                            <Col sm={12} className="text-right d-flex align-items-center mb-5">
                                <Button variant="primary" className="mr-auto rounded-circle backBtn"><i class="fas fa-arrow-left"></i></Button>
                                <Button variant="outline-primary"><i class="far fa-2x fa-file-archive"></i> Download All</Button>
                            </Col>
                            <Col sm={12} className="mb-2">
                                <Form.Check type="checkbox" label="Select All" />
                            </Col>
                            <Col lg={3} sm={12} className="mb-3">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={3} sm={12} className="mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <span className="checkIcon"><InputGroup.Checkbox aria-label="Checkbox for following text input" /></span>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal 
                    className="downloadModal videoModal" 
                    show={openDownloadModal} 
                    size="md" 
                    aria-labelledby="contained-modal-title-vcenter" 
                    centered
                    onHide={() =>this.setState({openDownloadModal: false}) }
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Video Title
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col sm={12}>
                            <video width="100%" height="100%" controls="true" autoPlay>
                                <source src="/image/Video/Typing_dark_03_Videvo.mp4" type="video/mp4"/>
                            </video>
                        </Col>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default BucketScriptComponent;