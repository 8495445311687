import { SCRIPT_REVIEW_EDIT_SAVE, SCRIPT_SEARCH, VIDEO_SEARCH, VIDEO_BUCKET_RESPONSE, USER_DETAIL, VIDEO_LIKE_RESPONSE, LOAD_BUCKET_VIDEOS, CLEAR_BUCKET_VIDEOS, SINGLE_SCRIPT } from './actionTypes';
import { fetchRequest, checkTokenExpire, socket } from './apiRequest';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()

export const scriptReviewSave = (id, data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/script/${id}/edit`, requestOptions );
        checkTokenExpire(result);
        await dispatch({ type: SCRIPT_REVIEW_EDIT_SAVE, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const scriptSearch = query => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await fetchRequest(`/script?${query}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SCRIPT_SEARCH, payload: result });
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }
}

export const recentScriptSearch = query => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type" : "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await fetchRequest(`/recent-script?${query}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SCRIPT_SEARCH, payload: result });
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }
}

export const loadScriptVideos = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/load-script-videos`, requestOptions );
        checkTokenExpire(result);
        await dispatch({ type: VIDEO_SEARCH, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const videoSelected = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/video-bucket`, requestOptions );
        // console.log('result',result)
        // checkTokenExpire(result);
        await dispatch({ type: VIDEO_BUCKET_RESPONSE, payload: result})
        // socket.emit("video-bucket", data);
    }catch(error){
        // console.log('error',error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const videoLike = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/video-like`, requestOptions );
        // checkTokenExpire(result);
        await dispatch({ type: VIDEO_LIKE_RESPONSE, payload: result})
        // socket.emit("video-bucket", data);
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const loadBucketVideos = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/load-bucket-videos`, requestOptions );
        checkTokenExpire(result);
        await dispatch({ type: LOAD_BUCKET_VIDEOS, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }   
}

// export const downloadVideo = blobname => async dispatch => {
//     try {
//         const requestOptions = {
//             method: 'GET',
//             headers: {
//                 "Content-Type" : "application/json; charset=utf-8",
//                 "Authorization": authHeader()
//             },
//         }
//         const result = await fetchRequest(`/download/footage/${blobname}`, requestOptions);
//         checkTokenExpire(result);
//         await dispatch({ type: SCRIPT_SEARCH, payload: result });
//     } catch (error) {
//         if(error && error.status === 401){
//             checkTokenExpire(error.data)
//         }else{
//             toast.dismiss()
//             toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
//         }
//     }
// }

export const clearBucket = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( `/clear-video-bucket`, requestOptions );
        // checkTokenExpire(result);
        await dispatch({ type: CLEAR_BUCKET_VIDEOS, payload: result})
        // socket.emit("video-bucket", data);
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}




export const singleScript=(scriptId)=>async dispatch=>{
    try {
        const requestScript = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await fetchRequest(`/script/${scriptId}`, requestScript);
        // checkTokenExpire(result);
        await dispatch({ type: SINGLE_SCRIPT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            // checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}