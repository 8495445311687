import { SCRIPT_UPLOAD, SCRIPT_REVIEW_EDIT_SAVE, SCRIPT_SEARCH, VIDEO_SEARCH, VIDEO_BUCKET_RESPONSE, VIDEO_LIKE_RESPONSE, LOAD_BUCKET_VIDEOS, CLEAR_BUCKET_VIDEOS, SINGLE_SCRIPT } from "../actions/actionTypes"

const initialState = { scriptUpload:'', scriptEditResponse:'', scriptSearchResponse:'', loadVideosResponse:'', videoBucketResponse:'', loadVideosBucketResponse :'', clearBucketResponse:'' , singleScriptData: ''  }

export default ( state = initialState, action ) => {
    switch ( action.type ){
        case SCRIPT_UPLOAD:
            return {
                ...state,
                scriptUpload: action.payload
            }
        case SCRIPT_REVIEW_EDIT_SAVE:
            return {
                ...state,
                scriptEditResponse: action.payload
            }
        case SCRIPT_SEARCH:
            return {
                ...state,
                scriptSearchResponse: action.payload
            }
        case VIDEO_SEARCH:
            return {
                ...state,
                loadVideosResponse: action.payload
            }
        case VIDEO_BUCKET_RESPONSE:
            return {
                ...state,
                videoBucketResponse: action.payload
            }
        case VIDEO_LIKE_RESPONSE:
            return {
                ...state,
                videoLikeResponse: action.payload
            }
        case LOAD_BUCKET_VIDEOS:
            return {
                ...state,
                loadVideosBucketResponse: action.payload
            }
        case CLEAR_BUCKET_VIDEOS:
            return {
                ...state,
                clearBucketResponse: action.payload
            }

        case SINGLE_SCRIPT:
            return {
                ...state,
                singleScriptData: action.payload
            } 
        default:
            return state;
    }
}