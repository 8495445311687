import { combineReducers } from "redux";
import authenticateReducer from './authenticateReducer';
import scriptReducer from './scriptReducer';
import userReducer from './userReducer';
import themeReducer from './themeReducer';

export default combineReducers({
    script: scriptReducer,
    auth: authenticateReducer,
    user: userReducer,
    theme: themeReducer,
})