import React, { Component } from 'react';
import { Container, Row, Col, Modal, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { decodedToken } from '../../helpers/decode-token';
import { toast } from "react-toastify";
import { videoSelected, videoLike } from '../../actions/scriptActions';
import axios from 'axios';
var FileSaver = require('file-saver');
class VideoScriptComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            openDownloadModal: false,
            videoDetail:{},
            videos:[],
            progress:0,
            selectedDownloadVideoID:''
        }
    }

    componentDidMount = async () =>{
        this.setState({
            videos: this.props.videos
        })
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        this.setState({
            videos: nextProps.videos
        })
    }

    downloadVideo = async (video) => {
        try{
            let media = video
            this.setState({ selectedDownloadVideoID: media._id })
            let progress = 0
            FileSaver.saveAs(media.mediaUrl, media.media);
            axios({
                url: media.mediaUrl,
                onDownloadProgress:  (progressEvent) => {
                    progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    this.setState({progress})
                }
            }).then(() => {
                setTimeout(() => {
                    this.setState({
                        selectedDownloadVideoID: ''
                    })
                }, 5000);
            });
        }catch(e){
            toast.dismiss()
            toast.error("Unable to download, please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }


    videoBucket = async (videoId) => {
        let obj = {
            userId: decodedToken.userId,
            videoId:videoId
        }

        await this.props.videoSelected(obj);

        if(this.props.videoBucketResponse !== undefined && this.props.videoBucketResponse.success){
            let { videos } =this.state;

            let new_videos = [];

            if(this.props.videoBucketResponse.inserted){

                new_videos = videos.map(p =>{
                    if(p._id === this.props.videoBucketResponse.video.videoId){

                        p.bucketVideos = [ this.props.videoBucketResponse.video.userId, ...p.bucketVideos ];
                        return p;
                    }else{
                        return p
                    }
                });

            }else if(this.props.videoBucketResponse.deleted){
                new_videos = videos.map(p =>{
                    if(p._id === this.props.videoBucketResponse.video.videoId){
                        p.bucketVideos = p.bucketVideos.filter((item) => item !== this.props.videoBucketResponse.video.userId);
                        return p;
                    }else{
                        return p;
                    }
                });
            }

            this.setState({
                videos: new_videos
            })

        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    videoLike = async (videoId) => {
        let obj = {
            userId: decodedToken.userId,
            videoId:videoId
        }

        await this.props.videoLike(obj);

        if(this.props.videoLikeResponse !== undefined && this.props.videoLikeResponse.success){
            let { videos } =this.state;

            let new_videos = [];

            if(this.props.videoLikeResponse.inserted){

                new_videos = videos.map(p =>{
                    if(p._id === this.props.videoLikeResponse.video.videoId){

                        p.likeVideoUserIds = [ this.props.videoLikeResponse.video.userId, ...p.likeVideoUserIds ];
                        return p;
                    }else{
                        return p
                    }
                });

            }else if(this.props.videoLikeResponse.deleted){
                new_videos = videos.map(p =>{
                    if(p._id === this.props.videoLikeResponse.video.videoId){
                        p.likeVideoUserIds = p.likeVideoUserIds.filter((item) => item !== this.props.videoLikeResponse.video.userId);
                        return p;
                    }else{
                        return p;
                    }
                });
            }

            this.setState({
                videos: new_videos
            })

        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    render(){
        const { openDownloadModal, videoDetail, videos } = this.state
        // let { videos } =this.props;
        return(
            <>
                <div className="videoScript px-md-5 pt-2 pb-0">
                    <Container fluid>
                        <Row className="justify-content-center">
                            {videos.length > 0 && videos.map((video,idx)=>{
                                return(
                                    <Col lg={4} sm={12} className="mt-5 mb-3 mb-md-0">
                                        <Card className="scriptCol">
                                            {video.bucketVideos.find( id => id === decodedToken.userId ) ?
                                            <div className="right-icon">
                                                <i class="fas fa-check"></i>
                                            </div>
                                            : 
                                            null
                                            }
                                            <a className="bitIcon">
                                                <i class="fab fa-bitbucket" onClick={() => this.videoBucket(video._id)}></i>
                                            </a>
                                            <div className="imageWrap">
                                                <span className="playIcon" 
                                                    onClick={() => {
                                                        this.setState({ 
                                                            openDownloadModal: true,
                                                            videoDetail: video  
                                                        })
                                                    }
                                                }>
                                                    <i class="fas fa-play"></i>
                                                </span>
                                                <video width="100%" height="220px" poster={video.mediaUrl}>
                                                    <source src={video.mediaUrl} type="video/mp4"/>
                                                </video>
                                            </div>
                                            <Card.Body>
                                                <Card.Text>
                                                    <span><i class={video.likeVideoUserIds.find( id => id === decodedToken.userId ) ? "fas fa-heart heart-selected" : "fas fa-heart" } onClick={()=> this.videoLike(video._id)}></i> {video.likeVideoUserIds.length}</span>
                                                    {this.state.selectedDownloadVideoID == video._id 
                                                    ? <span> { this.state.progress < 100 ? `Downloading ` : `Downloaded`} {this.state.progress}%</span>
                                                    : <span onClick={() => this.downloadVideo(video)}>
                                                        <i class="fas fa-download"/> Download
                                                    </span>}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                )
                            })}

                            
                            {/* <Col lg={4} sm={12} className="mt-5 mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <a href="/auth/script/bucket" className="bitIcon"><i class="fab fa-bitbucket"></i></a>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                    <Card.Body>
                                        <Card.Text>
                                            <span><i class="fas fa-heart"></i> 234</span>
                                            <span><i class="fas fa-download"></i> Download</span>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col> */}
                            {/* <Col lg={4} sm={12} className="mt-5 mb-3 mb-md-0">
                                <Card className="scriptCol">
                                    <a href="/auth/script/bucket" className="bitIcon"><i class="fab fa-bitbucket"></i></a>
                                    <div className="imageWrap">
                                        <span className="playIcon" onClick={() => this.setState({ openDownloadModal: true })}><i class="fas fa-play"></i></span>
                                        <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." />
                                    </div>
                                    <Card.Body>
                                        <Card.Text>
                                            <span><i class="fas fa-heart"></i> 234</span>
                                            <span><i class="fas fa-download"></i> Download</span>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
                <Modal 
                    className="downloadModal videoModal" 
                    show={openDownloadModal} 
                    size="md" 
                    aria-labelledby="contained-modal-title-vcenter" 
                    centered
                    onHide={() =>this.setState({openDownloadModal: false, videoDetail:{} }) }
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {videoDetail.media}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col sm={12}>
                            <video width="100%" height="100%" controls="true" autoPlay>
                                <source src={videoDetail.mediaUrl} type="video/mp4"/>
                            </video>
                        </Col>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

// export default VideoScriptComponent;
const mapStateToProps = state => {
    const { script } = state;
    return {
        videoBucketResponse: script.videoBucketResponse,
        videoLikeResponse: script.videoLikeResponse
    }
}

const dispatchActions = { videoSelected, videoLike }

export default connect(mapStateToProps, dispatchActions)(VideoScriptComponent)
