import React, { Component } from 'react'
import Sidebar from "react-sidebar";
import { Link  } from 'react-router-dom';
import { isLoggedIn } from '../../helpers/decode-token';


class SideBar extends Component{

    showMenus = () => {
        return (
            <>
                <div className="siteLogo">
                    <span>CV</span> Global
                </div>
                <ul className="main-sidebar">
                    <li> <Link to={isLoggedIn ? '/auth' : '/'} onClick={() => this.props.handleViewSidebar()}> Home </Link></li>
                    <li> <Link to="/auth/script/upload" onClick={() => this.props.handleViewSidebar()}> Upload Script </Link></li>
                    <li> <Link to="/auth/script/search" onClick={() => this.props.handleViewSidebar()}> Search Script </Link></li>
                </ul>
            </>
        )
    }

    render(){
        return(
            <Sidebar
                sidebar={
                    this.showMenus()
                }
                open={this.props.isOpen}
                onSetOpen={this.props.handleViewSidebar}
                styles={{ sidebar: { background: "rgb(27 27 27)" } }}
            >
                {this.props.showBars()}
            </Sidebar>
        )
    }
}

export default SideBar