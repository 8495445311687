import React, { Component } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { decodedToken } from '../../helpers/decode-token';

class HomeComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            role: decodedToken ? decodedToken.role : null
        }
    }

    render(){
        const { role } = this.state
        return(
            <div className="default_sec contact_sec hv-100 homeBg">
                <Container fluid className="h-100">
                    <Row className="align-items-center h-100 justify-content-end">
                        <div className="imageBlock">
                            <img src="./image/coding-man.jpg" alt="..." />
                        </div>
                        <Col md={8}>
                            <Form id="contentdata">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={10} lg={7}>
                                        <p className="mb-3"><strong>CV Script AI Project</strong></p>
                                        <h3 className="mb-5">Script Storage and Document warehouse w/ AI film builder...</h3>        
                                        <div className="buttons-group mt-3">
                                            <Button onClick={() => this.props.history.push('/auth/script/upload')}  className="btn btn-outline-primary mr-3"  disabled={ role ? role[0] === 'user': false  }>Upload Script</Button>
                                            <Button onClick={() => this.props.history.push('/auth/script/search')} className="btn btn-outline-primary">Search for Script</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>                
                </Container>
            </div>
        )
    }
}

export default HomeComponent;