import React, { Component } from 'react';
import { Col, Row, Container, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { decodedToken } from '../../helpers/decode-token';
import { ENV } from '../../env'
import { logout } from '../../actions/authenticateActions';
import { getUser } from '../../actions/userActions';
import { isLoggedIn } from '../../helpers/decode-token';
import { Link } from 'react-router-dom'

class HeaderComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            fullname: decodedToken ? decodedToken.fullname : '',
            userId: decodedToken ? decodedToken.userId : '',
            profile: decodedToken ? decodedToken.profile: '', 
            videoCount:0,
        }
    }

    componentDidMount = async () =>{
        if(decodedToken){
            await this.props.getUser(decodedToken.userId);
            if(this.props.userDetail.success){
                this.setState({
                    videoCount: this.props.userDetail.user.videoCount
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        if(nextProps.videoBucketResponse.success && nextProps.videoBucketResponse.userId == decodedToken.userId){
            this.setState({
                videoCount: nextProps.videoBucketResponse.videoCount
            })
        }

        if(nextProps.clearBucketResponse.success && nextProps.clearBucketResponse.userId == decodedToken.userId){
            this.setState({
                videoCount: nextProps.clearBucketResponse.videoCount
            })
        }
    }

    render(){
        const { fullname, profile, videoCount } = this.state
        return(
            <header>
                <Container fluid>
                    <Row className="align-items-center justify-content-end">
                        <Col sm={6} className="text-right">
                            {isLoggedIn ?
                                <Dropdown>
                                    <div className="bitbucket">
                                        <span>{videoCount}</span><i class="fab fa-bitbucket" onClick={() => window.location.replace('/auth/video-bucket') }></i>
                                    </div>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {profile 
                                            ? 
                                            <img src={ENV.imageApi + '/' + profile} height="25px" width="25px" alt="avtaar"/>
                                            :
                                            <svg viewBox="0 0 45.532 45.532">
                                                <path d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765   S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53   c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012   c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592   c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"/>
                                            </svg>
                                        }
                                        <span> {fullname} </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href={`/auth/user/profile`}> Profile </Dropdown.Item>
                                        <Dropdown.Item onClick={()=> { this.props.logout() }}>
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            :
                                <>
                                    <Link className="headLink" to="/login">Login</Link> 
                                    {/* | <Link className="headLink" to="/register">Signup</Link> */}
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </header>
        )
    }
}

const mapStateToProps = state => {
    const { user,script } = state;
    // console.log('user',user)
    return {
        userDetail: user.get,
        videoBucketResponse: script.videoBucketResponse,
        clearBucketResponse:script.clearBucketResponse
    }
}

const dispatchActions = {
    logout,
    getUser
}

export default connect(mapStateToProps, dispatchActions)(HeaderComponent);