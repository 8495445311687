import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { login, resetPassword, socialLogin  } from '../../actions/authenticateActions';
import * as check from '../../helpers/validation';
import { isLoggedIn } from '../../helpers/decode-token';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig  } from "../../authConfig";
import  jwt_decode  from 'jwt-decode';

class Login extends Component{
    constructor(props){
        super(props)
        // if(isLoggedIn){
        //     window.location.replace('/auth')
        // }
        this.state = {
            fields: {},
            errors: {},
            isValid:false,
            btnDisable: false,
            sid:new URLSearchParams(this.props.location.search).get("sid"),
            alert:{
                message:'',
                show: false,
                variant:''
            },
            forgotEmail: '',
            forgotError:'',
            mBtnDisable:false,
            scriptId :this.props.match.params.scriptId
        }
        this.PublicClientApplication = new PublicClientApplication(msalConfig);
    }


    componentDidMount(){

        if(isLoggedIn){
            if(this.state.sid){

                window.location.replace(`/review/script/${ window.atob(this.state.sid)}`)

            }
        }
        let alert ={}
        const check = ['null' , null , 'undefined' , undefined , '']
        this.PublicClientApplication.handleRedirectPromise().then(async (tokenResponse) => {
            if(tokenResponse !== null){
                const DecodeToken = jwt_decode(tokenResponse.idToken)
                const name = DecodeToken.preferred_username.split('.')
                var obj= {
                    email: DecodeToken.email ,
                    fullname: name[0],
                    uniqueId: DecodeToken.aud,
                    signupType:'Azure',
                    emailVerification:"verified" , 
                    scriptId : !check.includes(this.state.sid) ?  window.atob(this.state.sid) : null
                }
                await this.props.socialLogin(obj);
                if(this.props.auth.success){
                    alert['show'] = true
                    alert['message'] = this.props.auth.message
                    alert['variant'] = "success"
                    this.setState({ btnDisable: true, alert })
                    if(this.props.auth.scriptId){

                        window.location.replace(`/review/script/${this.props.auth.scriptId}`)

                    }
                    setTimeout(() => {
                        window.location.replace('/auth')
                    }, 800);
                }else{
                    alert['show'] = true
                    alert['message'] = this.props.auth.message
                    alert['variant'] = "danger"
                    this.setState({ btnDisable: false, alert })
                    
                }

            }
        }).catch((error) => {
            // console.log("error" , error)
            // alert['show'] = true
            // alert['message'] = 'Oops, Please try again later'
            // alert['variant'] = "danger"
            this.setState({ btnDisable: false, alert })
        });
    }

    // fieldValidation = (name) => {
    //     const { fields } = this.state
    //     let errors = this.state.errors
    //     let isValid = true
    //     if(name === 'email'){
    //         if(!fields['email']){
    //             isValid = false
    //             errors['email'] = "Can't be blank"
    //         }
    
    //         if(!check.EmailValidation(fields['email']) || fields['email'] === undefined ){
    //             isValid = false
    //             errors['email'] = "Invalid email"
    //         }
    //     }

    //     if(name === 'password'){
    //         if(!fields['password']){
    //             isValid = false
    //             errors['password'] = "Can't be blank"
    //         }
    
    //         if(fields['password'] !== undefined){
    //             if(fields['password'].trim().length < 1){
    //                 isValid = false
    //                 errors['password'] = "Can't be blank"
    //             }
    //         }
    //     }
        

    //     this.setState({ errors })
    //     return isValid
    // }


    // inputValidation = () => {
    //     const { fields } = this.state
    //     let isValid = true

    //     if(!fields['email']){
    //         isValid = false
    //     }

    //     if(!check.EmailValidation(fields['email']) || fields['email'] === undefined ){
    //         isValid = false
    //     }

    //     if(!fields['password']){
    //         isValid = false
    //     }

    //     if(fields['password'] !== undefined){
    //         if(fields['password'].trim().length < 1){
    //             isValid = false
    //         }
    //     }

    //     return isValid
    // }

    // handleInputChange = (e) => {
    //     const { name, value } = e.target
    //     let errors = this.state.errors
    //     let fields = this.state.fields
    //     fields[name] = value


    //     let isFieldValid = this.fieldValidation(name);
    //     let isValid = this.inputValidation()

    //     if(isFieldValid){ 
    //         errors[name] = ""
    //     }

    //     this.setState({ fields, isValid, errors })

    // }

    // handleOnLogin = async (e) => {
    //     e.preventDefault();
    //     let alert = {}
    //     try {
    //         alert['show'] = true
    //         alert['message'] = 'Validating...'
    //         alert['variant'] = "success"
    //         this.setState({ btnDisable: true, alert })
    //         await this.props.login(this.state.fields)
    //         if(this.props.auth.success){
    //             alert['show'] = true
    //             alert['message'] = this.props.auth.message
    //             alert['variant'] = "success"
    //             this.setState({ btnDisable: true, alert })
    //             setTimeout(() => {
    //                 window.location.replace('/auth')
    //             }, 800);
    //         }else{
    //             alert['show'] = true
    //             alert['message'] = this.props.auth.message
    //             alert['variant'] = "danger"
    //             this.setState({ btnDisable: false, alert })
    //         }
    //     } catch (error) {
    //             alert['show'] = true
    //             alert['message'] = 'Please try again'
    //             alert['variant'] = "danger"
    //             this.setState({ btnDisable: false, alert })
    //     }
        
    // }

    // onForgotPassword = () => {
    //     swal({
    //         text: 'Enter Your Registered Email',
    //         content: "input",
    //         button: {
    //           text: "Submit",
    //           closeModal: false,
    //         },
    //       }).then(async email => {
    //         if (email && check.EmailValidation){
    //             return await this.props.resetPassword({email})
    //         };

    //         throw null;
    //       }).then( async () => {
    //             if(this.props.auth.success){
    //                 swal({
    //                     text: this.props.auth.message,
    //                     icon: "success",
    //                 });
    //             }else{
    //                 swal({
    //                     text: this.props.auth.message,
    //                     icon: "error",
    //                 });
    //             }
    //       }).catch(err => {
    //         if (err) {
    //           swal("Oh no!", "Request failed!", "error");
    //         } else {
    //           swal.stopLoading();
    //           swal.close();
    //         }
    //       });
    // }

    ssoLogin =async () =>{
        let alert = {}
        try {
           await  this.PublicClientApplication
            .loginRedirect({
                ...msalConfig.auth, 
                scopes:["user.read"],
                // scopes: [ "openid", "offline_access" ]  
            })
        } catch (err) {
                alert['show'] = true
                alert['message'] = 'Please try again'
                alert['variant'] = "danger"
                this.setState({ btnDisable: false, alert }) 
        }
    }
    showAlertMessage = () => {
        return (
            <Alert variant={this.state.alert['variant']}> { this.state.alert['message']} </Alert>
        )
    }

    render(){

        const { errors , isValid, alert, btnDisable} = this.state

        return (
            <section className="py-5 loginForm">
                <Container className="h-100">
                    <Row className="justify-content-md-center align-items-center h-100">
                        <Col sm={7}>
                            {alert['show'] && this.showAlertMessage()}
                            <div className="bg-light p-4 shadow-lg text-center">
                                <div className="logo-box d-flex align-items-center justify-content-center mb-4">
                                    <img className="loginLogo" src="image/cv-black.png" width="80" height="50"/>
                                    <span className="loginLogoText" style={{ textTransform:'uppercase' }}>Global</span>
                                </div>
                                <Button onClick={()=> this.ssoLogin()}>
                                    <span className="mr-3">
                                        <img src="image/microsoft.png" width="30" height="30" />
                                    </span>
                                    Login in with Microsoft
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

const mapStateToProps = state => {
    const { auth } = state
    return {
        auth: auth.user
    }
}

const dispatchActions = {
    login, resetPassword, socialLogin
}

export default connect(mapStateToProps, dispatchActions)(Login) 