import { LIST_THEMES, LIST_SUB_THEMES } from "../actions/actionTypes"

const initialState = { themes:'', subThemes:'' }

export default ( state = initialState, action ) => {
    switch ( action.type ){
        case LIST_THEMES:
            return {
                ...state,
                themes: action.payload
            }
        case LIST_SUB_THEMES:
            return {
                ...state,
                subThemes: action.payload
            }
        default:
            return state;
    }
}