import { GET_USER, UPDATE_PROFILE } from './actionTypes';
import { fetchRequest, checkTokenExpire, postImageAndFile } from './apiRequest';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()

export const getUser = (id) => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await fetchRequest( `/user/${id}`, requestOptions );
        checkTokenExpire(result);
        await dispatch({ type: GET_USER, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateProfile = (formData, userId) => async dispatch => {
    try{
        const result = await postImageAndFile(`/user/${userId}`, formData );
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_PROFILE, payload: result})
    }catch(error){ 
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}