import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Spinner, Alert, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropzone from "react-dropzone";
import { scriptReviewSave , singleScript } from '../../actions/scriptActions';
import { getThemes, getSubThemes } from '../../actions/themeActions';
import { checkTokenExpire } from '../../actions/apiRequest';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import  jwt_decode  from 'jwt-decode';
import * as check from '../../helpers/validation';
import axios from 'axios';
import authHeader  from '../../helpers/auth-header';
import { ENV } from '../../env';
import { toast } from "react-toastify";
import { isLoggedIn } from '../../helpers/decode-token';

toast.configure()

class ReviewScript extends Component{
    constructor(props){
        super(props)
        this.state = {
            scriptId : this.props.match.params.scriptId,
            uploadedScriptId : '' ,
            scriptData : '' , 
            loading : false , 
            themes: [],
            subThemes : [],
            fields: {
                title:'',
                themeId:'',
                subThemeId:'',
                feltNeed:'',
                language:'',
                tags:[],
                // script: ''
            },
            errors: {},
            isFormValid:false,
            submitLoader: false,
            approve:{
                theme: false,
                themeId: false,
                subThemeId: false,
                feltNeed: false,
                language:false,
                tags: false,
            },
            showInvalidUrl : false ,
            editScriptId: '',


        }
    }

    componentDidMount = async() => {
        // localStorage.setItem('scriptDBToken', JSON.stringify(this.state.token));
        await this.getScript();
        await this.fetchThemes();
       
    }


    getScript = async()=>{
        this.setState({loading:true});
        // const token = jwt_decode(this.state.token) 
        this.setState({uploadedScriptId: this.state.scriptId})
        await this.props.singleScript(this.state.scriptId)
        if(this.props.singleScriptData.success){
            if(this.props?.singleScriptData?.data){
                this.setState({editScriptId:this.props?.singleScriptData?.data._id})
                this.setState({scriptData:this.props?.singleScriptData?.data})
                const result = this.props?.singleScriptData ;
                let fields = this.state.fields
                        fields['language'] = check.capitalizeFirstLetter(result?.data?.language);
                        fields['tags'] = result?.data?.tags;
                        fields['title'] = result?.data?.title;
                        fields['feltNeed'] = result?.data?.feltNeed;
                        fields['script'] = result.data.script
                        if(result?.data?.themeId){
                            fields['themeId'] = result?.data?.themeId
                            await this.fetchSubThemes(result?.data?.themeId)
                            fields['subThemeId'] = result?.data?.subThemeId
                        }
                        this.setState({
                            fields,
                            loading:false
                        })
            }
        }else{
            toast.dismiss()
            toast.error( this.props.singleScriptData.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.props.history.push('/auth')

            
            
            this.setState({loading:false})

        }

    }


    fetchThemes = async () =>{
        this.setState({loading:true})
        await this.props.getThemes();
        if(this.props.themes.response){
            this.setState({
                loading:false,
                themes: this.props.themes.themes
            })
        }else{
            this.setState({ loader : false })
            toast.dismiss()
            toast.error( this.props.themes.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }

    }

    fetchSubThemes = async (subThemeId) =>{
        await this.props.getSubThemes(subThemeId);
        if(this.props.subThemes.response){
            
            this.setState({
                subThemes: this.props.subThemes.sub_themes
            })
        }else{
            this.setState({ loader : true })
            toast.dismiss()
            toast.error( this.props.subThemes.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }

    }


    fieldValidation = (name) => {
        const { fields } = this.state
        let errors = this.state.errors
        let isValid = true
        
        if(name === 'themeId'){
            if(!fields['themeId']){
                isValid = false
                errors['themeId'] = "Please Select Theme"
            }
        }

        if(name === 'script'){
            if(!fields['script']){
                isValid = false
                errors['script'] = "please enter script "
            }
        }

        if(name === 'subThemeId'){
            if(!fields['subThemeId']){
                isValid = false
                errors['subThemeId'] = "Please Select Sub Theme"
            }
        }

        if(name === 'feltNeed'){
            if(!fields['feltNeed']){
                isValid = false
                errors['feltNeed'] = "Please Select Felt Need"
            }
        }

        if(name === 'title'){
            if(!fields['title']){
                isValid = false
                errors['title'] = "Can't be blank"
            }
    
            if(!check.AphabeticalsValidation(fields['title'])){
                isValid = false
                errors['title'] = "Enter only letters"
            }
    
            if(fields['title'] !== undefined ){
                if(fields['title'].trim().length < 1){
                    isValid = false
                    errors['title'] = "Can't be blank"
                }
                if(fields['title'].length < 3){
                    isValid = false
                    errors['title'] = "Minimum length must be 3"
                }
            }
        }

        if(name === 'language'){
            if(!fields['language']){
                isValid = false
                errors['language'] = "Can't be blank"
            }
    
            if(!check.AphabeticalsValidation(fields['language'])){
                isValid = false
                errors['language'] = "Enter only letters"
            }
    
            if(fields['language'] !== undefined ){
                if(fields['language'].trim().length < 1){
                    isValid = false
                    errors['language'] = "Can't be blank"
                }
                if(fields['language'].length < 3){
                    isValid = false
                    errors['language'] = "Minimum length must be 3"
                }
            }
        }

        this.setState({ errors })
        return isValid
    }

    isScriptFormValid = () => {
        const { fields, approve } = this.state
        let isValid = true
        
        if(!fields['themeId']){
            isValid = false
        }

        if(!fields['subThemeId']){
            isValid = false
        }
        
        if(!fields['feltNeed']){
            isValid = false
        }

        if(!fields['title']){
            isValid = false
        }

        if(!fields['script']){
            isValid = false
        }

        if(!check.AphabeticalsValidation(fields['title'])){
            isValid = false
        }

        if(fields['title'] !== undefined ){
            if(fields['title'].trim().length < 1){
                isValid = false
            }
            if(fields['title'].length < 3){
                isValid = false
            }
        }


        if(!fields['language']){
            isValid = false
            
        }

        if(!fields['tags'].length){
            isValid = false
        }

        if(!check.AphabeticalsValidation(fields['language'])){
            isValid = false
            
        }

        if(fields['language'] !== undefined ){
            if(fields['language'].trim().length < 1){
                isValid = false
                
            }
            if(fields['language'].length < 3){
                isValid = false
                
            }
        }

    // -----------------  Currently Not In use ----------------------- //
        // if(!(Object.keys(approve).every((k) => approve[k] === true))){
        //     isValid = false
        // }

        if(fields['tags'].length < 1){
            isValid = false
        }
    // -------------- Temp use ------ //
        return isValid
    }


    handleInput = (e) => {
        const { name, value } = e.target
        let errors = this.state.errors
        let fields = this.state.fields
        let approve = this.state.approve
        fields[name] = check.capitalizeFirstLetter(value)
        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isScriptFormValid();
        if(isFieldValid){ 
            errors[name] = ""
        }else{
            approve[name] = false
        }

        if(name == "themeId"){
            this.fetchSubThemes(value)
        }
        this.setState({ fields, isFormValid, errors })
    }  
    
    
    handleAddTag = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let errors = this.state.errors
            errors['tag'] = ''
            this.setState({ errors })
            if (this.inputTags.value.length < 1) return;

            if(this.inputTags.value.trim().length < 1) return;

            let fields = this.state.fields
            
            fields['tags'] = fields['tags'].slice() 
            let tagFound = fields['tags'].find(t => t.toLowerCase() === this.inputTags.value.toLowerCase())
            if(tagFound){
                errors['tag'] = `Can't add duplicate tag`
                this.setState({ errors })
                return;
            }

            fields['tags'].push(check.capitalizeFirstLetter(this.inputTags.value));
            errors['tag'] = ''

            // ----- Used till approve buttons are hidden ------ //
            // if(script['tags'].length > 0){
            //     this.setState({ isFormValid: true })
            // }
            // ----- Used till approve buttons are hidden ------ //

            this.setState({fields, errors});
            this.inputTags.value = '';
        }, 750)
    }


    handleRemoveTagOnDoubleClick = (tag) => {
        let { fields } = this.state
        fields['tags'] = fields['tags'].filter(t => t !== tag)
        if(fields['tags'].length < 1){
            let approve = this.state.approve
            approve['tags'] = false
            this.setState({ approve, isFormValid: false })
        }
        this.setState({fields})

    }

    handleShowWarning = (message) => {
        swal("Warning!", message, "warning");
    }


    handleScriptSave = async (e) => {
        e.preventDefault()
        let { fields, editScriptId } = this.state
        this.setState({ submitLoader: true })
        await this.props.scriptReviewSave(editScriptId, fields)
        if(this.props.scriptSubmitResponse.success){
            this.handleResetForm()
            toast.dismiss()
            toast.success(this.props.scriptSubmitResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            clearTimeout(this.scriptSaveTime)
            this.props.history.push('/')
            
        }else{
            this.setState({ submitLoader: false })
            toast.dismiss()
            toast.error(this.props.scriptSubmitResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }


    handleResetForm = () => {
        this.setState({
            FormValid: false,
            
            errors:{},
            fields: {
                title:'',
                themeId:'',
                subThemeId:'',
                feltNeed:'',
                length:'',
                language:'',
                tags:[],
                script:''
            },
             ploadedScriptId:'',
            approve:{
                theme: false,
                length: false,
                language:false,
                tags: false,
             },
            submitLoader: false,
        })
    }

    
    
    render(){

        const { fields, isFormValid, errors, approve, submitLoader, themes, subThemes, loading , scriptData , showInvalidUrl} = this.state ;

        return (
            <div>
                <Row className="justify-content-center mb-5">
                    <Col sm={12} lg={8} >
                    {loading   ? <h3 style={{"textAlign":"center"}}>Redirecting...</h3> :
                    showInvalidUrl ?  <h3 className="redirect-url">Invalid Redirect Url</h3> :
                    <>
                        <Form className="scriptForm" onSubmit={this.handleScriptSave}>
                            <h3 className="text-center">Script Analysis</h3>
                            <p className="text-center">Please review the script analysis and make any changes before approving.</p>
                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <h4>&nbsp;</h4>
                                    <div className="form-group">
                                        <label htmlFor="title">Title</label>
                                        <input type="text" className="form-control" id="title" name="title" aria-describedby="title" placeholder="Enter Title" defaultValue={fields['title']} onChange={this.handleInput}/>
                                        <small style={{ color:'#db1313'}}> { errors['title'] || '' } </small>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="themeId">Theme</label>
                                        
                                        <Form.Control as="select" id="themeId" name="themeId" aria-describedby="themeId" onChange={this.handleInput} defaultValue={fields['themeId']}>
                                            <option value="" disabled>Select Theme</option>
                                            
                                            {
                                                (themes.length > 0 && themes.map(( theme, idx) => {
                                                return (
                                                    <option value={theme._id}>{theme.name}</option>
            
                                                )
                                                }))
                                            }
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['themeId'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>

                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="subThemeId">Select Sub Theme</label>
                                        
                                        <Form.Control as="select" id="subThemeId" name="subThemeId" aria-describedby="subThemeId" onChange={this.handleInput} defaultValue={fields['subThemeId']}>
                                            <option value="" disabled>Select Sub Theme</option>
                                            
                                            {
                                                (subThemes.length > 0 && subThemes.map(( subTheme, idx) => {
                                                return (
                                                    <option value={subTheme._id}>{subTheme.name}</option>
                                                )
                                                }))
                                            }
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['subThemeId'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>

                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="feltNeed">Felt Need</label>
                                        
                                        <Form.Control as="select" id="feltNeed" name="feltNeed" aria-describedby="feltNeed" onChange={this.handleInput} defaultValue = {fields['feltNeed'] !== undefined ? fields['feltNeed'] :  fields['feltNeed'] = '' }>
                                            <option value="" disabled>Select Felt Need</option>
                                            <option value="Lonely">Lonely</option>
                                            <option value="Empty">Empty</option>
                                            <option value="Broken">Broken</option>
                                            <option value="Shame">Shame</option>
                                            <option value="Angry">Angry</option>
                                            <option value="Fear">Fear</option>
                                            <option value="Happy">Happy</option>
                                            <option value="Sad">Sad</option>
                                            <option value="Surprise">Surprise</option>
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['feltNeed'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>
                            
                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="language">Language</label>
                                        
                                        <Form.Control as="select" id="language" name="language" aria-describedby="language" onChange={this.handleInput} defaultValue={fields['language']}>
                                            <option value="" disabled>Select language</option>
                                            <option value="English" >English</option>
                                            
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['language'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>

                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="language">Script</label>
                                        
                                        <Form.Control as="textarea" id="script" name="script" aria-describedby="script" onChange={this.handleInput} defaultValue={fields['script']}>
                                            
                                        </Form.Control>
                                        <small style={{ color:'#db1313'}}> { errors['script'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row className="justify-content-around">
                                <Col md={8} lg={5}>
                                    <div className="form-group">
                                        <label htmlFor="tags">Video Tags</label>
                                        {fields['tags'].length > 0 &&
                                        <ul>
                                            {fields['tags'].map(t => (
                                                <OverlayTrigger 
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-project`}>
                                                            Double click to remove
                                                        </Tooltip>
                                                    }
                                                >
                                                    <li onDoubleClick={() => this.handleRemoveTagOnDoubleClick(t)}>
                                                        {t}
                                                    </li>
                                                </OverlayTrigger>
                                            ))}
                                        </ul>
                                        }
                                        <div className="inputs">
                                            <input  className="form-control mb-1" id="tag" name="tag" aria-describedby="tag" ref={r => this.inputTags = r} placeholder="Enter Tag"  onKeyUp={this.handleAddTag}/>
                                        </div>
                                        <small style={{ color:'#db1313'}}> { errors['tag'] || '' } </small>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col md={12} className="text-center">
                                    { this.state.fields['title'] && this.state.fields['themeId'] && this.state.fields['subThemeId'] && this.state.fields ['feltNeed'] &&  this.state.fields['script'] && this.state.fields['language'] && this.state.fields['tags'].length > 0 ?
                                    
                                        <Button type="submit" variant="primary" className="text-capitalize" disabled = {submitLoader}>{ submitLoader ? 'Wait...' : 'Submit'}</Button>
                                    
                                    :   
                                        <Button type="submit" variant="primary" className="text-capitalize" disabled>{ submitLoader ? 'Wait...' : 'Submit'}</Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                        <Col>
                        
                        </Col>

                        </>
                    
                    }
                    </Col>
                </Row>
            </div>    
          

            
        )
    }
        
    
}


const mapStateToProps = state => {
  
    const { script, theme } = state
    return {
        scriptSubmitResponse: script.scriptEditResponse,
        themes:theme.themes,
        subThemes:theme.subThemes,
        singleScriptData: script.singleScriptData
    }
}
const dispatch = { 
    scriptReviewSave,
    getThemes,
    getSubThemes,
    singleScript
}
export default connect(mapStateToProps, dispatch)(ReviewScript);

