import React, { Component } from 'react';


class NotFound extends Component{
    render(){
        return(
            <div className="text-center">
                <img className="m-auto" src="image/error-page.jpg" alt=".." />                
            </div>
        )
    }
}

export default NotFound