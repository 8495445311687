
// ------------ AUTHENTICATE CONSTANT --------- //
export const USER_REGISTER = "USER_REGISTER";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD"
export const USER_DETAIL = "USER_DETAIL"


// ------------- SCRIPT CONSTANT ------------- //
export const SCRIPT_UPLOAD = "SCRIPT_UPLOAD";
export const SCRIPT_REVIEW_EDIT_SAVE = "SCRIPT_REVIEW_EDIT_SAVE";
export const SCRIPT_SEARCH = "SCRIPT_SEARCH";
export const VIDEO_SEARCH = "VIDEO_SEARCH";
export const VIDEO_BUCKET_RESPONSE = "VIDEO_BUCKET_RESPONSE";
export const VIDEO_LIKE_RESPONSE = "VIDEO_LIKE_RESPONSE";
export const LOAD_BUCKET_VIDEOS = "LOAD_BUCKET_VIDEOS";
export const CLEAR_BUCKET_VIDEOS = "CLEAR_BUCKET_VIDEOS";
export const SINGLE_SCRIPT = " SINGLE_SCRIPT "

//  -----------------  USER CONSTANT ------------ //
export const GET_USER = "GET_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

//  -----------------  THEME CONSTANT ------------ //
export const LIST_THEMES = "LIST_THEMES";
export const LIST_SUB_THEMES = "LIST_SUB_THEMES";



