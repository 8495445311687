import React, { Component} from 'react';
import Layout from './components/Layout';
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
class App extends Component{
  render(){
    return (
        <div className="main-app">
            <Layout/>
        </div>
    )
  }
}

export default App;
