import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Spinner, Alert, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropzone from "react-dropzone";
import { scriptReviewSave } from '../../actions/scriptActions';
import { getThemes, getSubThemes } from '../../actions/themeActions';
import { checkTokenExpire } from '../../actions/apiRequest';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import * as check from '../../helpers/validation';
import axios from 'axios';
import authHeader  from '../../helpers/auth-header';
import { ENV } from '../../env';
import { toast } from "react-toastify";
toast.configure()

class UploadScriptComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            isFormValid: false,
            // --- First Step Sfile:'',
            selectedFile:'',
            scriptUploadedPercent:0,
            scriptUploadingProgress: false,
            scriptProcessing: false,
            // --- First Step State Variable --- //

            // --- Third Step --- //
            extractedData:'',
            errors:{},
            script: {
                title:'',
                themeId:'',
                subThemeId:'',
                feltNeed:'',
                language:'',
                tags:[]
            },
            uploadedScriptId:'',
            approve:{
                theme: false,
                themeId: false,
                subThemeId: false,
                feltNeed: false,
                language:false,
                tags: false,
            },
            submitLoader: false,
            // --- Third Step --- //

            // --- Step Form --- //
            step_upload: true,
            step_aiScan: false,
            step_reviewEdit: false,
            // --- Step Form --- // 

            alert:{
                message:'',
                show: false,
                variant:''
            },
            loader: true,
            themes:[],
            subThemes:[]
        }

        this.initialState = JSON.parse(JSON.stringify(this.state))
    }


    componentDidMount = async () =>{
        this.fetchThemes();
    }

    fetchThemes = async () =>{
        await this.props.getThemes();
        if(this.props.themes.response){
            this.setState({
                loader:false,
                themes: this.props.themes.themes
            })
        }else{
            this.setState({ loader : false })
            toast.dismiss()
            toast.error( this.props.themes.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }

    }

    fetchSubThemes = async (subThemeId) =>{
        // this.setState({ loader : true })
        await this.props.getSubThemes(subThemeId);
        if(this.props.subThemes.response){
            
            this.setState({
                // loader:false,
                subThemes: this.props.subThemes.sub_themes
            })
        }else{
            this.setState({ loader : true })
            toast.dismiss()
            toast.error( this.props.subThemes.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }

    }

    changeStep(step){
        switch(step){
            case 1: this.setState({
                        step_upload: true,
                        step_aiScan: false,
                        step_reviewEdit: false,
                    });
                    break;
            case 2: this.setState({
                        step_upload: false,
                        step_aiScan: true,
                        step_reviewEdit: false,  
                    })
                    break;
            case 3: this.setState({
                        step_upload: false,
                        step_aiScan: false,
                        step_reviewEdit: true,  
                    })
                    break;
            default: break;
        }
    }

    /**
     * 
     * @function checkextension is used to check file extension on upload 
     */
    
    checkextension = (file) => {
        if ( /\.(pdf|rtf|srt|tsv|xlsx|xls|csv|doc|txt|docx)$/i.test(file.name.toLowerCase()) === false ) { return false };
        return true
    }

    /**
     * 
     * @function handleDrop is used to get file on upload 
     */


    handleDrop = async acceptedfile => {
        const file = acceptedfile[0]
      
        let alert = {}
        
        if(file){
                alert['show'] = false
                alert['variant']= ''
                alert['message'] =''
                this.setState({ alert })
            let isFileValid = this.checkextension(file)
            if(isFileValid){
                let selectedFile = file
                alert['show'] = false
                alert['variant']= ''
                alert['message'] =''
                this.setState({ alert, selectedFile })
              
                let formData = new FormData();
                formData.append('scriptData', file)
            
                try {
                    const config = {
                        headers: {
                            "Content-Type": "multipart/form-data; charset=utf-8;",
                            'Authorization': authHeader()
                        },
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            this.setState({
                                scriptUploadedPercent:percentCompleted,
                                scriptUploadingProgress: true,
                            })
                            setTimeout(() => {
                                if(percentCompleted >= 100){
                                    this.setState({
                                        scriptProcessing: true,
                                    })
                                }
                            }, 150)
                            
                        },
                    }
                    const response = await axios.post( `${ENV.mainApi + "/script/upload"}`, formData, config);
                    const result = await response.data;
                   
                    this.setState({ scriptProcessing : false, scriptUploadedPercent: 0, scriptUploadingProgress: false })
                    if(result && result.success){
                        this.changeStep(2)
                    
                        let script = this.state.script
                        script['language'] = check.capitalizeFirstLetter(result.data.language);
                        script['tags'] = result.data.tags;
                        script['title'] = result.data.title;
                        script['feltNeed'] = result.data.feltNeed;
                        if(result.data.themeId){
                            script['themeId'] = result.data.themeId
                            await this.fetchSubThemes(result.data.themeId)
                            script['subThemeId'] = result.data.subThemeId
                        }


                        this.setState({
                            script,
                            uploadedScriptId: result.data._id
                        })

                        

                        // let isFormValid = this.isScriptFormValid();
                        // this.setState({isFormValid})
                        setTimeout(()=> {
                            this.changeStep(3)
                        }, 3500)
                    }else{
                        alert['show'] = true
                        alert['variant']= 'danger'
                        alert['message'] = result.message || 'Something went wrong, try after some time'
                        this.setState({ alert })
                        setTimeout(() => {
                            this.changeStep(1)
                        }, 1200)
                    }

                } catch (e) {
                    
                    if(e.response && e.response.status === 401){
                        checkTokenExpire(e.response.data)
                    }else{
                        toast.dismiss()
                        toast.error('Something Went Wrong, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                    }
                    this.setState({ scriptUploadedPercent: 0, scriptUploadingProgress: false, scriptProcessing: false })
                }
            }else{
                alert['show'] = true
                alert['variant']= 'danger'
                alert['message'] ='Not a valid script file'
                this.setState({ alert })
            }
        }else{
            alert['show'] = true
            alert['variant']= 'danger'
            alert['message'] ='Select only single file to drag/drop or upload'
            this.setState({ alert })
        }
    } 

    // ------------------- First Step ------------------- //
    step_upload = () => {
        const { alert, scriptUploadedPercent, scriptUploadingProgress, scriptProcessing } = this.state
        
        return (
            <div className="fileDrag">
                <Dropzone onDrop={this.handleDrop} multiple={false} disabled={scriptUploadingProgress}>
                    {({getRootProps, getInputProps, isDragActive}) => (
                        <section>
                            <div {...getRootProps()}>
                                {alert['show'] && <Alert className="mt-2" variant={this.state.alert['variant']}> { this.state.alert['message']} </Alert>}
                            
                                <input {...getInputProps()} disabled={scriptUploadingProgress}/>
                                {isDragActive ? 
                                    <>
                                        <svg id="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-17 1h4v-8h2v8h4l-5 6-5-6z" fill="#272727"/>
                                        </svg>
                                        <h3>Drop script here to Upload</h3>
                                    </>
                                :
                                    <>
                                        <svg id="color" viewBox="0 0 24 24">
                                            <path d="m14.25 0h-11.5c-1.52 0-2.75 1.23-2.75 2.75v15.5c0 1.52 1.23 2.75 2.75 2.75h6.59c-.54-1.14-.84-2.41-.84-3.75 0-1.15.22-2.25.64-3.26.2-.51.45-1 .74-1.45.65-1.01 1.49-1.87 2.48-2.54.51-.35 1.05-.64 1.63-.86.93-.39 1.95-.61 3.01-.63v-5.76c0-1.52-1.23-2.75-2.75-2.75z" fill="#eceff1"/>
                                            <g fill="#90a4ae">
                                                <path d="m14 9c0 .05 0 .1-.01.14-.58.22-1.12.51-1.63.86h-8.36c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1z"/>
                                                <path d="m9.88 12.54c-.29.45-.54.94-.74 1.45-.04.01-.09.01-.14.01h-5c-.55 0-1-.45-1-1s.45-1 1-1h5c.38 0 .72.22.88.54z"/>
                                                <path d="m8 6h-4c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1z"/>
                                            </g>
                                            <path d="m17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75 6.75 3.028 6.75 6.75-3.028 6.75-6.75 6.75z" fill="#272727"/>
                                            <path d="m17.25 21c-.552 0-1-.448-1-1v-5.5c0-.552.448-1 1-1s1 .448 1 1v5.5c0 .552-.448 1-1 1z" fill="#fff"/>
                                            <path d="m20 18.25h-5.5c-.552 0-1-.448-1-1s.448-1 1-1h5.5c.552 0 1 .448 1 1s-.448 1-1 1z" fill="#fff"/>
                                        </svg>
                                        <h3>Drag script anywhere to Upload</h3>
                                        <p>Supported Format ( pdf | rtf | srt | tsv | xlsx | xls | csv | doc | txt | docx )</p>
                                    </> 

                                }
                                <p className="my-4 text-uppercase">OR</p>
                                <Button variant="outline-primary" className="text-capitalize">Click to Upload</Button> 
                                {scriptUploadingProgress && <ProgressBar className="mt-3" now={scriptUploadedPercent} label={scriptUploadedPercent >= 100 ? `Uploaded ${scriptUploadedPercent}%` : `Uploading ${scriptUploadedPercent}%`} />}
                                {scriptProcessing && <p className="text-center mt-2"> Please Wait Processing... </p>}
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        )
    }


    // ------------------------- Second Step -------------------------- //
    step_aiScan = () => {
        return (
            <div className="py-5 text-center">
                <h3>Please wait while document is being scanned...</h3>
                <Spinner animation="border" className="mt-5" />
            </div>
        )
    }

    // ------------------------- Third Step ---------------------------- //
        /**
         * 
         * @function handleDelete is used to delete video tags
         * @function handleAddition is used to Add Video Tags
         * @function handleDrag is used to drag to new postion from current position  
         */
    
    fieldValidation = (name) => {
        const { script } = this.state
        let errors = this.state.errors
        let isValid = true
        
        if(name === 'themeId'){
            if(!script['themeId']){
                isValid = false
                errors['themeId'] = "Please Select Theme"
            }
        }

        if(name === 'subThemeId'){
            if(!script['subThemeId']){
                isValid = false
                errors['subThemeId'] = "Please Select Sub Theme"
            }
        }

        if(name === 'feltNeed'){
            if(!script['feltNeed']){
                isValid = false
                errors['feltNeed'] = "Please Select Felt Need"
            }
        }

        if(name === 'title'){
            if(!script['title']){
                isValid = false
                errors['title'] = "Can't be blank"
            }
    
            if(!check.AphabeticalsValidation(script['title'])){
                isValid = false
                errors['title'] = "Enter only letters"
            }
    
            if(script['title'] !== undefined ){
                if(script['title'].trim().length < 1){
                    isValid = false
                    errors['title'] = "Can't be blank"
                }
                if(script['title'].length < 3){
                    isValid = false
                    errors['title'] = "Minimum length must be 3"
                }
            }
        }

        if(name === 'language'){
            if(!script['language']){
                isValid = false
                errors['language'] = "Can't be blank"
            }
    
            if(!check.AphabeticalsValidation(script['language'])){
                isValid = false
                errors['language'] = "Enter only letters"
            }
    
            if(script['language'] !== undefined ){
                if(script['language'].trim().length < 1){
                    isValid = false
                    errors['language'] = "Can't be blank"
                }
                if(script['language'].length < 3){
                    isValid = false
                    errors['language'] = "Minimum length must be 3"
                }
            }
        }

        this.setState({ errors })
        return isValid
    }

    isScriptFormValid = () => {
        const { script, approve } = this.state
        let isValid = true
        
        if(!script['themeId']){
            isValid = false
        }

        if(!script['subThemeId']){
            isValid = false
        }
        
        if(!script['feltNeed']){
            isValid = false
        }

        if(!script['title']){
            isValid = false
        }

        if(!check.AphabeticalsValidation(script['title'])){
            isValid = false
        }

        if(script['title'] !== undefined ){
            if(script['title'].trim().length < 1){
                isValid = false
            }
            if(script['title'].length < 3){
                isValid = false
            }
        }


        if(!script['language']){
            isValid = false
            
        }

        if(!script['tags'].length){
            isValid = false
        }

        if(!check.AphabeticalsValidation(script['language'])){
            isValid = false
            
        }

        if(script['language'] !== undefined ){
            if(script['language'].trim().length < 1){
                isValid = false
                
            }
            if(script['language'].length < 3){
                isValid = false
                
            }
        }

    // -----------------  Currently Not In use ----------------------- //
        // if(!(Object.keys(approve).every((k) => approve[k] === true))){
        //     isValid = false
        // }

        if(script['tags'].length < 1){
            isValid = false
        }
    // -------------- Temp use ------ //
        return isValid
    }
    

    handleInput = (e) => {
        const { name, value } = e.target
        let errors = this.state.errors
        let script = this.state.script
        let approve = this.state.approve
        script[name] = check.capitalizeFirstLetter(value)

        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isScriptFormValid();



        if(isFieldValid){ 
            errors[name] = ""
        }else{
            approve[name] = false
        }

        if(name == "themeId"){
            this.fetchSubThemes(value)
        }

        this.setState({ script, isFormValid, errors })
    }   

    // it will get hit after 700ms  when user stop typing 
    handleAddTag = (e) => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            let errors = this.state.errors
            errors['tag'] = ''
            this.setState({ errors })
            if (this.inputTags.value.length < 1) return;

            if(this.inputTags.value.trim().length < 1) return;

            let script = this.state.script
            
            script['tags'] = script['tags'].slice() 
            let tagFound = script['tags'].find(t => t.toLowerCase() === this.inputTags.value.toLowerCase())
            if(tagFound){
                errors['tag'] = `Can't add duplicate tag`
                this.setState({ errors })
                return;
            }

            script['tags'].push(check.capitalizeFirstLetter(this.inputTags.value));
            errors['tag'] = ''

            // ----- Used till approve buttons are hidden ------ //
            // if(script['tags'].length > 0){
            //     this.setState({ isFormValid: true })
            // }
            // ----- Used till approve buttons are hidden ------ //

            this.setState({script, errors});
            this.inputTags.value = '';
        }, 750)
    }

    handleRemoveTagOnDoubleClick = (tag) => {
        let { script } = this.state
        script['tags'] = script['tags'].filter(t => t !== tag)
        if(script['tags'].length < 1){
            let approve = this.state.approve
            approve['tags'] = false
            this.setState({ approve, isFormValid: false })
        }
        this.setState({script})

    }

    handleShowWarning = (message) => {
        swal("Warning!", message, "warning");
    }

    // handleApprove = (e) => {
    //     const { name, checked } = e.target
    //     let approve = this.state.approve
    //     approve[name] = checked
    //     let isFormValid = false

    //     this.setState({ 
    //         approve 
    //     }, ()=> {
    //         isFormValid = this.isScriptFormValid()
    //     })

    //     setTimeout(() => {
    //         this.setState({ isFormValid })
    //     }, 100)
    // }   

    handleScriptSave = async (e) => {
        e.preventDefault()
        let { script, uploadedScriptId } = this.state
        // script['length'] = Number(script['length'])
        this.setState({ submitLoader: true })
        await this.props.scriptReviewSave(uploadedScriptId, script)

        if(this.props.scriptSubmitResponse.success){
            this.handleResetForm()
            toast.dismiss()
            toast.success(this.props.scriptSubmitResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            clearTimeout(this.scriptSaveTime)
            
        }else{
            this.setState({ submitLoader: false })
            toast.dismiss()
            toast.error(this.props.scriptSubmitResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }


    step_reviewEdit = () => {

        const { script, isFormValid, errors, approve, submitLoader, themes, subThemes, loader } = this.state

        return(
            <Row className="justify-content-center mb-5">
                <Col sm={12} lg={8}>
                    <Form className="scriptForm" onSubmit={this.handleScriptSave}>
                        <h3 className="text-center">Script Analysis</h3>
                        <p className="text-center">Please review the script analysis and make any changes before approving.</p>
                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <h4>&nbsp;</h4>
                                <div className="form-group">
                                    <label htmlFor="title">Title</label>
                                    <input type="text" className="form-control" id="title" name="title" aria-describedby="title" placeholder="Enter Title" defaultValue={script['title']} onChange={this.handleInput}/>
                                    <small style={{ color:'#db1313'}}> { errors['title'] || '' } </small>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <h4>&nbsp;</h4>
                                <div className="form-group">
                                    <label htmlFor="theme">Theme</label>

                                    <input type="text" className="form-control" id="theme" name="theme" aria-describedby="theme" placeholder="Enter theme" defaultValue={script['theme']} onChange={this.handleInput}/>
                                    
                                    <small style={{ color:'#db1313'}}> { errors['theme'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row> */}

                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <div className="form-group">
                                    <label htmlFor="themeId">Theme</label>
                                    
                                    <Form.Control as="select" id="themeId" name="themeId" aria-describedby="themeId" onChange={this.handleInput} defaultValue={script['themeId']}>
                                        <option value="" disabled>Select Theme</option>
                                        
                                        {
                                            (themes.length > 0 && themes.map(( theme, idx) => {
                                            return (
                                                <option value={theme._id}>{theme.name}</option>
        
                                            )
                                            }))
                                        }
                                    </Form.Control>
                                    <small style={{ color:'#db1313'}}> { errors['themeId'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row>

                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <div className="form-group">
                                    <label htmlFor="subThemeId">Select Sub Theme</label>
                                    
                                    <Form.Control as="select" id="subThemeId" name="subThemeId" aria-describedby="subThemeId" onChange={this.handleInput} defaultValue={script['subThemeId']}>
                                        <option value="" disabled>Select Sub Theme</option>
                                        
                                        {
                                            (subThemes.length > 0 && subThemes.map(( subTheme, idx) => {
                                            return (
                                                <option value={subTheme._id}>{subTheme.name}</option>
                                            )
                                            }))
                                        }
                                    </Form.Control>
                                    <small style={{ color:'#db1313'}}> { errors['subThemeId'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row>

                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <div className="form-group">
                                    <label htmlFor="feltNeed">Felt Need</label>
                                    
                                    <Form.Control as="select" id="feltNeed" name="feltNeed" aria-describedby="feltNeed" onChange={this.handleInput} defaultValue = {script['feltNeed'] !== undefined ? script['feltNeed'] :  script['feltNeed'] = '' }>
                                        <option value="" disabled>Select Felt Need</option>
                                        <option value="Lonely">Lonely</option>
                                        <option value="Empty">Empty</option>
                                        <option value="Broken">Broken</option>
                                        <option value="Shame">Shame</option>
                                        <option value="Angry">Angry</option>
                                        <option value="Fear">Fear</option>
                                        <option value="Happy">Happy</option>
                                        <option value="Sad">Sad</option>
                                        <option value="Surprise">Surprise</option>
                                    </Form.Control>
                                    <small style={{ color:'#db1313'}}> { errors['feltNeed'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row>
                        
                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <div className="form-group">
                                    <label htmlFor="language">Language</label>
                                    
                                    <Form.Control as="select" id="language" name="language" aria-describedby="language" onChange={this.handleInput} defaultValue={script['language']}>
                                        <option value="" disabled>Select language</option>
                                        <option value="English" >English</option>
                                        
                                    </Form.Control>
                                    <small style={{ color:'#db1313'}}> { errors['language'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row>
                        <Row className="justify-content-around">
                            <Col md={8} lg={5}>
                                <div className="form-group">
                                    <label htmlFor="tags">Video Tags</label>
                                    {script['tags'].length > 0 &&
                                    <ul>
                                        {script['tags'].map(t => (
                                            <OverlayTrigger 
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-project`}>
                                                        Double click to remove
                                                    </Tooltip>
                                                }
                                            >
                                                <li onDoubleClick={() => this.handleRemoveTagOnDoubleClick(t)}>
                                                    {t}
                                                </li>
                                            </OverlayTrigger>
                                        ))}
                                    </ul>
                                    }
                                    <div className="inputs">
                                        <input  className="form-control mb-1" id="tag" name="tag" aria-describedby="tag" ref={r => this.inputTags = r} placeholder="Enter Tag"  onKeyUp={this.handleAddTag}/>
                                    </div>
                                    <small style={{ color:'#db1313'}}> { errors['tag'] || '' } </small>
                                </div>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                { this.state.script['title'] && this.state.script['themeId'] && this.state.script['subThemeId'] && this.state.script ['feltNeed'] && this.state.script['language'] && this.state.script['tags'].length > 0 ?
                                
                                    <Button type="submit" variant="primary" className="text-capitalize" disabled = {submitLoader}>{ submitLoader ? 'Wait...' : 'Submit'}</Button>
                                   
                                :   
                                    <Button type="submit" variant="primary" className="text-capitalize" disabled>{ submitLoader ? 'Wait...' : 'Submit'}</Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        )
    }


    handleResetForm = () => {
       this.setState({
            isFormValid: false,
            // --- First Step Sfile:'',
            selectedFile:'',
            scriptUploadedPercent:0,
            scriptUploadingProgress: false,
            scriptProcessing: false,
            // --- First Step State Variable --- //

            // --- Third Step --- //
            extractedData:'',
            errors:{},
            script: {
                title:'',
                themeId:'',
                subThemeId:'',
                feltNeed:'',
                length:'',
                language:'',
                tags:[],
            },
            uploadedScriptId:'',
            approve:{
                theme: false,
                length: false,
                language:false,
                tags: false,
            },
            
            submitLoader: false,
            // --- Third Step --- //

            // --- Step Form --- //
            step_upload: true,
            step_aiScan: false,
            step_reviewEdit: false,
            // --- Step Form --- // 

            alert:{
                message:'',
                show: false,
                variant:''
            }
       })
    }

    render(){
        const { step_upload, step_aiScan, step_reviewEdit, loader } = this.state
        return(
            <div className="stepForm px-sm-5">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col sm={12}>
                            <ul className="navSteps uploadSteps">
                                <li className={step_upload ? 'active': ''}><span></span> Upload</li>
                                <li className={step_aiScan ? 'active': ''}><span></span> AI Scan</li>
                                <li className={step_reviewEdit ? 'active': ''}><span></span> Review/Edit</li>
                            </ul>
                            {step_upload && 
                            <div className="stepsContent">
                                {this.step_upload()}
                            </div>
                            }
                            { step_aiScan && this.step_aiScan()}
                            { step_reviewEdit && this.step_reviewEdit()}
                        </Col>
                    </Row>
                </Container>
            </div>
        )

      
    }
}

const mapStateToProps = state => {
  
    const { script, theme } = state
    return {
        scriptSubmitResponse: script.scriptEditResponse,
        themes:theme.themes,
        subThemes:theme.subThemes
    }
}
const dispatch = { 
    scriptReviewSave,
    getThemes,
    getSubThemes
}
export default connect(mapStateToProps, dispatch)(UploadScriptComponent);