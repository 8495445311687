import React, { Component } from 'react';
import { Container, Row, Col, Modal, Card, Button } from 'react-bootstrap';
import {connect} from 'react-redux';
import axios from "axios";
import { decodedToken } from '../../helpers/decode-token';
import { toast } from "react-toastify";
import { videoSelected, videoLike, loadBucketVideos, clearBucket } from '../../actions/scriptActions';
var FileSaver = require('file-saver');
var JSZip = require('jszip');


class VideoBucket extends Component{
    constructor(props){
        super(props)
        this.state = {
            openDownloadModal: false,
            videoDetail:{},
            videos:[],
            progress: 0,
            selectedDownloadVideoID:'',
        }
    }

    componentDidMount = async () =>{
        await this.props.loadBucketVideos({userId : decodedToken.userId})
        if(this.props.loadVideosBucketResponse.success){
            this.setState({
                videos: this.props.loadVideosBucketResponse.data
            })
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    downloadVideo = async (video) => {
        try{
            let media = video.videoId
            this.setState({ selectedDownloadVideoID: video._id })
            let progress = 0
            axios({
                url: media.mediaUrl,
                onDownloadProgress:  (progressEvent) => {
                    progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    this.setState({progress})
                }
            }).then(() => {
                setTimeout(() => {
                    this.setState({
                        selectedDownloadVideoID: ''
                    })
                }, 5000);
            });
            FileSaver.saveAs(media.mediaUrl, media.media);
        }catch(e){
            toast.dismiss()
            toast.error("Unable to download, please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    downloadBulkAsZip = () => {
        const { videos } = this.state
        let bucketVideos =  videos.map(video => video.videoId)
        const zip = new JSZip();
        // const otherFiles = zip.folder("cvglobal");  // create another folder inside the zip file
        const list = bucketVideos.map(async (media, index) => {        
            const response = await fetch(media.mediaUrl);
            const data = await response.blob();
            zip.file(`${media.media}`, data);
            return data;
        })
        Promise.all(list).then(function() {
            zip.generateAsync({type:"blob"})
            .then(function(content) {
              FileSaver.saveAs(content, `cvglobal-video-clips.zip`);
            });
        });
    }

    videoBucket = async (videoId) => {
        let obj = {
            userId: decodedToken.userId,
            videoId:videoId
        }

        await this.props.videoSelected(obj);

        if(this.props.videoBucketResponse !== undefined && this.props.videoBucketResponse.success){
            let { videos } =this.state;

            let new_videos = [];

            if(this.props.videoBucketResponse.inserted){

                new_videos = videos.map(p =>{
                    if(p.videoId._id === this.props.videoBucketResponse.video.videoId){

                        p.videoId.bucketVideos = [ this.props.videoBucketResponse.video.userId, ...p.videoId.bucketVideos ];
                        return p;
                    }else{
                        return p
                    }
                });

            }else if(this.props.videoBucketResponse.deleted){
                new_videos = videos.map(p =>{
                    if(p.videoId._id === this.props.videoBucketResponse.video.videoId){
                        p.videoId.bucketVideos = p.videoId.bucketVideos.filter((item) => item !== this.props.videoBucketResponse.video.userId);
                        return p;
                    }else{
                        return p;
                    }
                });
            }

            this.setState({
                videos: new_videos
            })

        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    videoLike = async (videoId) => {
        let obj = {
            userId: decodedToken.userId,
            videoId:videoId
        }

        await this.props.videoLike(obj);

        if(this.props.videoLikeResponse !== undefined && this.props.videoLikeResponse.success){
            let { videos } =this.state;

            let new_videos = [];

            if(this.props.videoLikeResponse.inserted){

                new_videos = videos.map(p =>{
                    if(p.videoId._id === this.props.videoLikeResponse.video.videoId){

                        p.videoId.likeVideoUserIds = [ this.props.videoLikeResponse.video.userId, ...p.videoId.likeVideoUserIds ];
                        return p;
                    }else{
                        return p
                    }
                });

            }else if(this.props.videoLikeResponse.deleted){
                new_videos = videos.map(p =>{
                    if(p.videoId._id === this.props.videoLikeResponse.video.videoId){
                        p.videoId.likeVideoUserIds = p.videoId.likeVideoUserIds.filter((item) => item !== this.props.videoLikeResponse.video.userId);
                        return p;
                    }else{
                        return p;
                    }
                });
            }

            this.setState({
                videos: new_videos
            })

        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    clearBucket = async () => {
        await this.props.clearBucket({userId : decodedToken.userId});
        if(this.props.clearBucketResponse.success){
            this.setState({
                videos: this.props.clearBucketResponse.videos
            })

        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }
    render(){
        const { openDownloadModal, videoDetail, videos } = this.state
        if(!videos.length){
            return (
                <div className="videoScript bucketScript px-md-5 pt-2 pb-5">
                     <Container fluid>
                        <Row className="justify-content-center mt-5">
                            <h3>No Videos Clips Selected</h3>
                        </Row>
                        <Row className="justify-content-center mt-5">
                            <Button variant="outline-primary"onClick={() => this.props.history.push('/auth/script/search')}> Click here to Search Script</Button>
                        </Row>
                    </Container>
                </div>
            )
        }
        return(
            <>
                <div className="videoScript bucketScript px-md-5 pt-2 pb-5">
                    <Container fluid>
                        <Row className="justify-content-center mt-5">
                            <Col sm={12} className="text-right d-flex align-items-center mb-5">
                                <Button variant="primary" className="mr-auto rounded-circle backBtn" onClick={() => this.props.history.push('/auth/script/search')}><i class="fas fa-arrow-left"></i></Button>
                                <Button variant="outline-primary" className="mr-1 clear-bucket" onClick={() => this.clearBucket()}>Clear Bucket</Button>
                                <Button variant="outline-primary" onClick={() => this.downloadBulkAsZip()}><i class="far fa-2x fa-file-archive"></i> Download All</Button>
                            </Col>
                            {/* <Col sm={12} className="mb-2">
                                <Form.Check type="checkbox" label="Select All" />
                            </Col> */}
                            {videos.length > 0 && videos.map((video,idx)=>{
                                
                                if(video.videoId == null || video.videoId.bucketVideos.find( id => id === decodedToken.userId ) == undefined ){
                                    return null;
                                }
                            return(
                                <Col lg={4} sm={12} className="mt-5 mb-3 mb-md-0">

                                    <Card className="scriptCol">
                                            {
                                                video.videoId.bucketVideos.find( id => id === decodedToken.userId ) ?
                                                <div className="right-icon">
                                                    <i class="fas fa-check"></i>
                                                </div>
                                            : 
                                                null
                                            }
                                        <a className="bitIcon">
                                            <i class="fab fa-bitbucket" onClick={() => this.videoBucket(video.videoId._id)}></i>
                                        </a>
                                        <div className="imageWrap">
                                            <span className="playIcon" onClick={() => 
                                                this.setState({ openDownloadModal: true,
                                                                videoDetail: video  })
                                            }>
                                                <i class="fas fa-play"></i>
                                            </span>
                                            {/* <Card.Img variant="top" src="/image/video-thumbnail.jpg" alt="..." /> */}
                                            <video width="100%" height="100%" poster={video.videoId.mediaUrl}>
                                                <source src={video.videoId.mediaUrl} type="video/mp4"/>
                                            </video>
                                        </div>
                                        <Card.Body>
                                            <Card.Text>
                                                <span><i class={video.videoId.likeVideoUserIds.find( id => id === decodedToken.userId ) ? "fas fa-heart heart-selected" : "fas fa-heart" } onClick={()=> this.videoLike(video.videoId._id)}></i> {video.videoId.likeVideoUserIds.length}</span>
                                                {this.state.selectedDownloadVideoID == video._id 
                                                ? <span> { this.state.progress < 100 ? `Downloading ` : `Downloaded`} {this.state.progress}%</span>
                                                : <span onClick={() => this.downloadVideo(video)}>
                                                    <i class="fas fa-download"/> Download
                                                </span>}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                )
                            })}
                        </Row>
                    </Container>
                </div>
                <Modal 
                    className="downloadModal videoModal" 
                    show={openDownloadModal} 
                    size="md" 
                    aria-labelledby="contained-modal-title-vcenter" 
                    centered
                    onHide={() =>this.setState({openDownloadModal: false, videoDetail:{} }) }
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {videoDetail.videoId !== undefined ? videoDetail.videoId.media : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col sm={12}>
                            <video width="100%" height="100%" controls="true" autoPlay>
                                <source src={videoDetail.videoId !== undefined ?  videoDetail.videoId.mediaUrl : ''} type="video/mp4"/>
                            </video>
                        </Col>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

// export default VideoBucket;
const mapStateToProps = state => {
    const { script } = state;
    return {
        videoBucketResponse: script.videoBucketResponse,
        videoLikeResponse: script.videoLikeResponse,
        loadVideosBucketResponse: script.loadVideosBucketResponse,
        clearBucketResponse:script.clearBucketResponse
    }
}

const dispatchActions = { videoSelected, videoLike, loadBucketVideos, clearBucket }

export default connect(mapStateToProps, dispatchActions)(VideoBucket);