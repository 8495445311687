import { USER_REGISTER, GET_USER, UPDATE_PROFILE } from "../actions/actionTypes"

const initialState = { user:'', get:'' }

export default ( state = initialState, action ) => {
    switch ( action.type ){
        case USER_REGISTER:
            return {
                ...state,
                user: action.payload
            }
        case GET_USER:
            return {
                ...state,
                get: action.payload
            }
        case UPDATE_PROFILE: {
            return {
                ...state,
                update: action.payload
            }
        }
        default:
            return state;
    }
}