import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { Button } from 'react-bootstrap'
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import SideBar from '../SidebarComponent/SidebarComponent';
import ROUTES, { RenderRoutes } from "../../routes";
// import { socket } from '../../actions/apiRequest';
// import { decodedToken } from '../../helpers/decode-token';
// import { VIDEO_BUCKET_RESPONSE } from '../../actions/actionTypes';

class Layout extends Component{
    constructor(props){
        super(props)

        this.state = {
            sidebarOpen: false
        }
    }

    // componentDidMount = async () => {

    //     if(decodedToken !== null){
    //         socket.on("video-bucket-response", async (data) => {
    //             // user = JSON.parse(user);
    //             // user = window.atob(user)
    //             console.log('data',data)
    //             await this.props.videoBucketResponse(data)
    //         });
    //     }
    // }

    handleViewSidebar = () => {
        this.setState({sidebarOpen: !this.state.sidebarOpen});
    }

    showBars = () => {
        return (
            <Button className="hamburgerMen" onClick={() => this.handleViewSidebar()}>
                <svg viewBox="0 -53 384 384"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg>
            </Button>
        )
    }

    render(){
        return (
            <>
                <HeaderComponent {...this.props} />
                <SideBar isOpen = {this.state.sidebarOpen} handleViewSidebar = {this.handleViewSidebar} showBars= {this.showBars}/>
                <div className="main-app-content">
                    <RenderRoutes routes={ROUTES} />
                </div>
            </>
        )
    }
}

// const mapStateToProps = state => {
//     const { script } = state
//     return {
//         videoInsertedResponse: script.videoInsertedResponse
//     }
//   }
//   const mapDispatchToProps = dispatch => {
//     return {
//         videoBucketResponse: async payload => { await dispatch({ type: VIDEO_BUCKET_RESPONSE, payload }) }  
//     }
//   }
//   export default connect(null, mapDispatchToProps)(Layout);
export default Layout;