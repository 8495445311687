import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Card, Dropdown, Modal} from 'react-bootstrap';
import * as check from '../../helpers/validation';
import { isLoggedIn } from '../../helpers/decode-token';
import { recentScriptSearch, loadScriptVideos } from '../../actions/scriptActions';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { ENV } from '../../env'
import axios from 'axios';
import { jsPDF } from "jspdf";
import ReactPaginate from 'react-paginate';
import VideoScriptComponent from '../SearchScriptComponent/VideoScriptComponent';
class LandingPage extends Component{   

    constructor(props){
        super(props)
        this.state = {

            script:{
                theme:'',
                length:''
            },
            step_build: false,
            isFormValid: false,
            scriptSearchLoader: false,
            searchResults:[],
            selectedScript:'',
            pageNumber:1,
            page:1,
            current_number:'',
            total_number:'',
            errors: {},
            per_page:10,
            total_pages:null,
            windowSize: "",
            thumbWidth: 75,
            openDownloadModal: false,

            // ---- Google Drive ---- //
            // googleAuth:'',
            fileName:'',
            createDriveOption: 1,

            loadingState: false, 
            loadMoreVideosLoader: false
           
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0)
        if(isLoggedIn){
            this.handleScriptSearch();
        }
    }

    handleScriptSearch = async () => {
        var { script,  pageNumber, page, total_pages } = this.state
        if(pageNumber == 1){

            this.setState({ loadingState: true})
        }
        // script['length'] = Number(script['length'])
        // let query = ''
        // query = `theme=${script['theme']}&length=${script['length']}&page=${page}`

        let query = ''
        query = `theme=${script['theme']}&page=${page}`

        this.setState({ scriptSearchLoader : true })

        await this.props.recentScriptSearch(query)
        if(this.props.searchResponse.success){

            var tracks = this.props.searchResponse.data;

            this.setState({ 
                searchResults: tracks,
                total_pages: this.props.searchResponse.meta.total_pages,
                scriptSearchLoader : false, 
                current_number: this.props.searchResponse.data.length,
                total_number: this.props.searchResponse.meta.total,
                loadingState:false,
            })
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }        
    }

    handleScriptSearchForm = async (e) => {
        e.preventDefault();
        var { script,  pageNumber, page } = this.state
        if(pageNumber == 1){
            this.setState({ loadingState: true})
        }
        // script['length'] = Number(script['length'])
        // let query = ''
        // query = `theme=${script['theme']}&length=${script['length']}&page=${page}`

        let query = ''
        query = `theme=${script['theme']}&page=${page}`

        this.setState({ scriptSearchLoader : true })

        await this.props.recentScriptSearch(query)
        if(this.props.searchResponse.success){

            var tracks = this.props.searchResponse.data;

            this.setState({ 
                searchResults: tracks,
                total_pages: this.props.searchResponse.meta.total_pages,
                scriptSearchLoader : false, 
                current_number: this.props.searchResponse.data.length,
                total_number: this.props.searchResponse.meta.total,
                loadingState:false,
            })
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
        
        
    }

    

    handleInput = (e) => {
        const { value, name } = e.target
        let script = this.state['script']
        let errors = this.state['errors']
        script[name] = check.capitalizeFirstLetter(value)

        let isFieldValid = this.fieldValidation(name);
        let isFormValid = this.isScriptFormValid();
        
        if(isFieldValid){ 
            errors[name] = ""
        }

        this.setState({ script, isFormValid, errors })
    }

    fieldValidation = (name) => {
        const { script } = this.state
        let errors = this.state.errors
        let isValid = true
        
        if(name === 'theme'){
            if(!script['theme']){
                isValid = false
                errors['theme'] = "Can't be blank"
            }
    
            if(script['theme'] !== undefined ){
                
                if(script['theme'].trim().length < 1){
                    isValid = false
                    errors['theme'] = "Can't be blank"
                }
                if(script['theme'].length < 1){
                    isValid = false
                    errors['theme'] = "Minimum length must be 3"
                }
                if(!check.AphabeticalsValidation(script['theme'])){
                    isValid = false
                    errors['theme'] = "Enter only letters"
                }
            }
        }

        // if(name === 'length'){
        //     if(!script['length']){
        //         isValid = false
        //         errors['length'] = "Can't be blank"
        //     }

        //     if(isNaN(script['length'])){
        //         isValid = false
        //         errors['length'] = "Enter length in number"
        //     }
        //     if(!isNaN(script['length'])){
        //         if(script['length'] < 30 ){
        //             isValid = false
        //             errors['length'] = 'Minimum length must be 30 Seconds'
        //         }
        //     }
        // }

        

        this.setState({ errors })
        return isValid
    }

    isScriptFormValid = () => {
        const { script } = this.state
        let isValid = true
        
        if(!script['theme']){
            isValid = false
        }

        if(!check.AphabeticalsValidation(script['theme'])){
            isValid = false
        }

        if(script['theme'] !== undefined ){
            if(script['theme'].trim().length < 1){
                isValid = false
            }
            if(script['theme'].length < 1){
                isValid = false
            }
        }

        // if(!script['length']){
        //     isValid = false
        // }

        // if(isNaN(script['length'])){
        //     isValid = false
        // }

        // if(!isNaN(script['length'])){
        //     if(script['length'] < 30 ){
        //         isValid = false
        //     }
        // }

        

        return isValid
    }

    handleSelectedScript = (script) => {
        this.setState({ selectedScript:script, step_build: true})
        this.handleVideoSearch(script);
    }

    handleVideoSearch = async (s) => {
        // console.log('script', s)
        const { script, tags } = this.state
        let obj = {
            title: script['title'] !== undefined && script['theme'] !==  ''?  script['title'] : s.title,
            theme: script['theme'] !== undefined  && script['theme'] !==  ''?  script['theme'] : s.themeId.name,
            tags: s.tags,
            script: s.script,
            selecteScriptTitle: s.title
        }
        
        this.setState({ 
            loadMoreVideosLoader: true 
        })

        await this.props.loadScriptVideos(obj);

        if(this.props.loadVideosResponse.success){
            this.setState({ 
                searchVideos: this.props.loadVideosResponse.videos,
                loadMoreVideosLoader: false
            })
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }

    step_build = () => {
        const { selectedScript, openDownloadModal, script,isFormValid, scriptSearchLoader, loadMoreVideosLoader } = this.state
        return(
            <>
                <Modal className="downloadModal" show={openDownloadModal} onHide={() => this.setState({ openDownloadModal: false, createDriveOption: 1 }, () => this.deleteTempFilesFromServer())} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Download
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={10}>
                                <p className="px-3"><strong>Choose a Format</strong></p>
                                <Dropdown>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" onMouseOver={this.createRefForDrive} onMouseLeave = {this.createRefForDrive}>
                                        Translation Document
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu ref = {elem => this.addToDriveref = elem}>
                                        <Dropdown.Item onClick={() => this.downloadAsPDF()}>  
                                            <svg viewBox="0 0 512 512" >
                                                <path d="M444.875,109.792L338.208,3.125c-2-2-4.708-3.125-7.542-3.125h-224C83.135,0,64,19.135,64,42.667v426.667     C64,492.865,83.135,512,106.667,512h298.667C428.865,512,448,492.865,448,469.333v-352     C448,114.5,446.875,111.792,444.875,109.792z M341.333,36.417l70.25,70.25h-48.917c-11.76,0-21.333-9.573-21.333-21.333V36.417z      M426.667,469.333c0,11.76-9.573,21.333-21.333,21.333H106.667c-11.76,0-21.333-9.573-21.333-21.333V42.667     c0-11.76,9.573-21.333,21.333-21.333H320v64C320,108.865,339.135,128,362.667,128h64V469.333z"/>
                                                <path d="M310.385,313.135c-9.875-7.771-19.26-15.76-25.51-22.01c-8.125-8.125-15.365-16-21.656-23.5     c9.813-30.323,14.115-45.958,14.115-54.292c0-35.406-12.792-42.667-32-42.667c-14.594,0-32,7.583-32,43.688     c0,15.917,8.719,35.24,26,57.698c-4.229,12.906-9.198,27.792-14.781,44.573c-2.688,8.052-5.604,15.51-8.688,22.406     c-2.51,1.115-4.948,2.25-7.302,3.427c-8.479,4.24-16.531,8.052-24,11.594C150.5,370.177,128,380.844,128,401.906     c0,15.292,16.615,24.76,32,24.76c19.833,0,49.781-26.49,71.656-71.115c22.708-8.958,50.938-15.594,73.219-19.75     c17.854,13.729,37.573,26.865,47.125,26.865c26.448,0,32-15.292,32-28.115c0-25.219-28.813-25.219-42.667-25.219     C337.031,309.333,325.49,310.604,310.385,313.135z M160,405.333c-6.094,0-10.219-2.875-10.667-3.427     c0-7.563,22.552-18.25,44.365-28.583c1.385-0.656,2.792-1.313,4.219-1.99C181.896,394.563,166.052,405.333,160,405.333z      M234.667,214.354c0-22.354,6.938-22.354,10.667-22.354c7.542,0,10.667,0,10.667,21.333c0,4.5-3,15.75-8.49,33.313     C239.135,233.75,234.667,222.698,234.667,214.354z M242.844,329c0.667-1.854,1.313-3.729,1.938-5.625     c3.958-11.875,7.521-22.542,10.698-32.146c4.427,4.875,9.198,9.865,14.313,14.979c2,2,6.958,6.5,13.563,12.135     C270.208,321.208,256.219,324.76,242.844,329z M362.667,334.552c0,4.792,0,6.781-9.896,6.844     c-2.906-0.625-9.625-4.583-17.917-10.229c3.01-0.333,5.229-0.5,6.479-0.5C357.094,330.667,361.563,332.208,362.667,334.552z"/>
                                            </svg>
                                            Pdf
                                        </Dropdown.Item>

                                        <Dropdown.Item onClick={() => this.downloadDocFile()}>
                                            <svg viewBox="0 0 512 512">
                                                <path d="M282.208,19.67c-3.648-3.008-8.48-4.256-13.152-3.392l-256,48C5.472,65.686,0,72.278,0,79.99v352    c0,7.68,5.472,14.304,13.056,15.712l256,48c0.96,0.192,1.984,0.288,2.944,0.288c3.68,0,7.328-1.28,10.208-3.68    c3.68-3.04,5.792-7.584,5.792-12.32v-448C288,27.222,285.888,22.71,282.208,19.67z M256,460.694L32,418.71V93.27l224-41.984    V460.694z"/>
                                                <path d="M496,79.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h208v288H272c-8.832,0-16,7.168-16,16    c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16v-320C512,87.158,504.832,79.99,496,79.99z"/>
                                                <path d="M432,143.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,151.158,440.832,143.99,432,143.99z"/>
                                                <path d="M432,207.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,215.158,440.832,207.99,432,207.99z"/>
                                                <path d="M432,271.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,279.158,440.832,271.99,432,271.99z"/>
                                                <path d="M432,335.99H272c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16    C448,343.158,440.832,335.99,432,335.99z"/>
                                                <path d="M209.76,176.086c-8.48-0.864-16.704,5.344-17.664,14.112l-8.608,77.504l-24.512-65.344    c-4.704-12.48-25.312-12.48-29.984,0l-26.016,69.408l-7.136-50.048c-1.248-8.768-9.44-14.976-18.112-13.568    c-8.736,1.248-14.816,9.344-13.568,18.08l16,112c1.024,7.264,6.848,12.896,14.112,13.664c7.424,0.736,14.144-3.424,16.704-10.272    L144,253.558l33.024,88.064c2.368,6.272,8.384,10.368,14.976,10.368c0.672,0,1.312-0.032,1.984-0.16    c7.328-0.896,13.088-6.752,13.92-14.08l16-144C224.864,184.982,218.56,177.078,209.76,176.086z"/>
                                            </svg>
                                            Word
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this. downloadTxtFile()}>
                                            <svg viewBox="0 0 24 24">
                                                <g><path d="m12.25 12c-.414 0-.75-.336-.75-.75v-.75h-7v.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-1.5c0-.414.336-.75.75-.75h8.5c.414 0 .75.336.75.75v1.5c0 .414-.336.75-.75.75z"/></g>
                                                <g><path d="m8 18.75c-.414 0-.75-.336-.75-.75v-8c0-.414.336-.75.75-.75s.75.336.75.75v8c0 .414-.336.75-.75.75z"/></g>
                                                <g><path d="m9.25 19h-2.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g>
                                                <g><path d="m20.25 10.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g>
                                                <g><path d="m20.25 14.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g>
                                                <g><path d="m20.25 18.5h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g>
                                                <g><path d="m21.25 23h-18.5c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h18.5c1.517 0 2.75 1.233 2.75 2.75v16.5c0 1.517-1.233 2.75-2.75 2.75zm-18.5-20.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-16.5c0-.689-.561-1.25-1.25-1.25z"/></g>
                                                <g><path d="m23.25 6h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g>
                                            </svg>
                                            text
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Row className="justify-content-center">
                    <Col sm={12} lg={9}>     
                        <Button type="submit" variant="primary" className="text-capitalize mt-3" onClick={()=> this.setState({ selectedScript:'', step_build: false}) }>Back</Button>
                    </Col>
                    <Col lg={6} sm={12} className="mt-5 mb-3 mb-md-0">
                        <Card className="scriptCol">
                            <Card.Body ref={r => this.outputScript = r} id="scriptContent">
                                <Card.Title> {selectedScript.title  ? selectedScript.title : 'Title N/A'} </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted "> {selectedScript.theme  ? selectedScript.theme : 'Theme N/A'}</Card.Subtitle>
                                <Card.Text style={{ whiteSpace:'pre-line'}}> {selectedScript.script} </Card.Text>
                                <Card.Title className="d-inline"> Keywords</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted d-inline "> {selectedScript.tags.length != 0 ? selectedScript.tags.join('/') : 'N/A'} </Card.Subtitle>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={5} sm={12} className="mt-5">
                        <div className="downloadFile" >
                            <Button type="submit" variant="outline-primary" className="mb-5" onClick={() => this.setState({ openDownloadModal: true })}>
                                <svg viewBox="0 0 24 24"><path d="m12 16c-.205 0-.401-.084-.542-.232l-5.25-5.5c-.456-.477-.117-1.268.542-1.268h2.75v-5.75c0-.689.561-1.25 1.25-1.25h2.5c.689 0 1.25.561 1.25 1.25v5.75h2.75c.659 0 .998.791.542 1.268l-5.25 5.5c-.141.148-.337.232-.542.232z" fill="#00a4b9"/><path d="m22.25 22h-20.5c-.965 0-1.75-.785-1.75-1.75v-.5c0-.965.785-1.75 1.75-1.75h20.5c.965 0 1.75.785 1.75 1.75v.5c0 .965-.785 1.75-1.75 1.75z" fill="#546d79"/><path d="m12 2h-1.25c-.689 0-1.25.561-1.25 1.25v5.75h-2.75c-.659 0-.998.791-.542 1.268l5.25 5.5c.141.148.337.232.542.232z" fill="#00a4b9"/><path d="m12 18h-10.25c-.965 0-1.75.785-1.75 1.75v.5c0 .965.785 1.75 1.75 1.75h10.25z" fill="#546d79"/></svg>
                                Download Document
                            </Button>
                            <p><strong>Length:</strong> {selectedScript.length} sec</p>
                            <p><strong>Tags:</strong> {selectedScript.tags.length != 0 ? selectedScript.tags.join(', ') : 'N/A'} </p>
                            <p><strong>Language:</strong> {selectedScript.language}</p>
                        </div>
                    </Col>
                </Row>
                <div className='shuffle'>
                    <Button type="submit" variant="outline-primary" className="mb-5" onClick={() => this.handleVideoSearch(this.state.selectedScript)}>                
                        Shuffle
                    </Button>
                </div>
                {  loadMoreVideosLoader 
                    ?
                    <div className='text-center'>
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                :
                    <VideoScriptComponent videos = {this.state.searchVideos}/>
                }
            </>
        )
    }

    createRefForDrive = async () => {
        if(this.addToDriveref !== null && this.addToDriveref !== undefined && this.state.createDriveOption === 1){
            let content = `${this.state.selectedScript.theme }\n\n\n`
            content += `${this.state.selectedScript.script}\n`;
            try{
                
                const response = await axios.post( `${ENV.mainApi + "/file"}`, {content});
                const result = await response.data;
                let fileURL =""
                let fileName=""

                if(result.success){
                    this.setState({ fileName: result.fileName })
                    fileName = result.fileName
                    fileURL = result.fileURL
                }

                this.script = document.createElement('script');
                this.script.src = "https://apis.google.com/js/platform.js"
                this.script.async = true;
                this.script.defer = true;
                this.addToDriveref.appendChild(this.script)
                    this.anchorTag = document.createElement('a')
                    this.anchorTag.href = "#";
                    this.anchorTag.className = "dropdown-item";
                    this.anchorTag.role = "button";
                    this.addToDriveref.appendChild(this.anchorTag)                   
                    
                this.saveToDriveDiv = document.createElement('div');
                this.saveToDriveDiv.className = "g-savetodrive";
                this.saveToDriveDiv.dataset.src = fileURL
                this.saveToDriveDiv.dataset.filename =  fileName;
                this.saveToDriveDiv.dataset.sitename = "CV Global";
        
                this.anchorTag.appendChild(this.saveToDriveDiv)
                this.setState({
                    createDriveOption: this.state.createDriveOption + 1
                })
            }catch (error){
            }
        }
    }

    deleteTempFilesFromServer = async () => {
        if(this.state.fileName){
            await axios.post(`${ENV.mainApi + "/file/remove"}`, {fileName: this.state.fileName});
        }
    }

    downloadTxtFile = () => {
        try{
            const element = document.createElement("a");
            let content = `${this.state.selectedScript.theme }\n\n\n`
            content += `${this.state.selectedScript.script}\n`;
            const file = new Blob([ content ], {type: 'text/plain;charset=utf-8'});
            element.href = URL.createObjectURL(file);
            element.download = Date.now() + ".txt";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setTimeout(() => {
                this.setState({ openDownloadModal: false })
            }, 2000)
        }catch(e){
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
        
    }

    downloadDocFile = () => {
        try{
            const element = document.createElement("a");
            let content = `${this.state.selectedScript.theme }\n\n\n`
            content += `${this.state.selectedScript.script}\n`;
            const file = new Blob([content], {type: 'application/msword;charset=utf-8'});
            element.href = URL.createObjectURL(file);
            element.download = Date.now() + ".docx";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setTimeout(() => {
                this.setState({ openDownloadModal: false })
            }, 2000)
        }catch (e){
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
        
    }

    downloadAsPDF = () => {
        try{
            const doc = new jsPDF();
            const { selectedScript } = this.state;
            doc.setFontSize(22);
            doc.text(selectedScript.theme, 20, 20); // title

            doc.setFontSize(14);
            let content = doc.splitTextToSize(selectedScript.script, 185)
            doc.text(content, 20, 30);
            doc.save(Date.now()+".pdf")
            setTimeout(() => {
                this.setState({ openDownloadModal: false})
            }, 2000)
        }catch (e){
            toast.dismiss()
            toast.error("Something went wrong, Please try again", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }               
    }

    loadMoreItems() {

        var { pageNumber} = this.state

        if(this.state.loadingState){
            return false;
        }
        pageNumber ++;
        this.setState({ loadingState: true, pageNumber: pageNumber });
        this.handleScriptSearch();
    }

    render(){
        const { script, searchResults,isFormValid, scriptSearchLoader, step_build, loadingState, page, total_pages, per_page, current_number, total_number} = this.state
        
        const paginate = e => {   
            this.setState({
                page:e.selected + 1
            }, ()=> {
                this.handleScriptSearch();
            })
        };

        if(!step_build){

            return (
                <>
                    <section className="pageBanner">
                        <Container className="text-center">
                            <img src="./image/banner-bg.jpg" alt="..." />
                            <h1>Script storage and 
                                <span className="d-block">Document warehouse</span>
                            </h1>
                            <div className="buttons-group">
                                <Button onClick={() => this.props.history.push('/auth/script/upload')} variant="outline-primary" className="mr-3">Upload Script</Button>
                                <Button onClick={() => this.props.history.push('/auth/script/search')} variant="outline-primary">Search for Script</Button>
                            </div>
                        </Container>
                    </section>
                    {isLoggedIn &&
                    <section className="trendingBlock py-5">
                        <Container>
                            <Row className="justify-content-center mb-4">
                                <Col sm={12} className="text-center mb-4">
                                    <h2 className="text-uppercase">Recent Scripts</h2>
                                </Col>
                                <Col sm={12} lg={9}>
                                    <Form className="scriptForm" onSubmit={this.handleScriptSearchForm}>
                                        <Row className="justify-content-around">
                                            
                                            <div className="form-group ">
                                                <label htmlFor="theme">Theme</label>
                                                <input type="text" className="form-control" id="theme" name="theme" aria-describedby="theme" placeholder="Enter Theme Ex. Love" onChange={this.handleInput} defaultValue={script['theme']}/>
                                            </div>
                                        
                                            {/* <div className="form-group">
                                                <label htmlFor="length">Length</label>
                                                <Form.Control as="select" id="length" name="length" aria-describedby="length" onChange={this.handleInput} defaultValue={script['length']}>
                                                    <option value="" disabled>Select length in seconds</option>
                                                    <option>15</option>
                                                    <option value="30">30</option>
                                                    <option value="45">45</option>
                                                    <option value="60">60</option>
                                                    <option value="90">90</option>
                                                    <option value="120">120</option>
                                                </Form.Control>
                                            </div> */}
                                            <div style={{marginTop:"28px", marginLeft: "-403px"}} >
                                                <Button type="submit" variant="primary" className="text-capitalize " style={{padding : "8px 6px"}} disabled={!isFormValid || scriptSearchLoader}>{scriptSearchLoader ? 'Wait...': 'Search'}</Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </Col>
                                {(searchResults && searchResults.length > 0) 
                                    ?
                                        searchResults.map( (s, idx) => {
                                            
                                            return (
                                                <Col sm={4} className="mb-3" key={idx}>
                                                    <Card className="scriptCol">
                                                        <Card.Body>
                                                            <Card.Title>{s.title  ? s.title : 'N/A'}</Card.Title>
                                                            <Card.Subtitle className="mb-2 text-muted "> {s.themeId  ? s.themeId.name : 'N/A'} </Card.Subtitle>
                                                            <Card.Text style={{ whiteSpace:'pre-line' }}> { s.script && s.script.substring(0, 280) + '...'} </Card.Text>
                                                            <Card.Title className="d-inline"> Keywords</Card.Title>
                                                            <Card.Subtitle className="mb-2 text-muted d-inline"> {s.tags.length !== 0 ? s.tags.slice(0,15).join(', ') + '...' : 'N/A'} </Card.Subtitle>
                                                            <Card.Link className="d-block" href="javascript:;" onClick={() => this.handleSelectedScript(s)}>Read More &#8594;</Card.Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                )
                                        }
                                        
                                    )
                                :
                                    <p style={{ border: '1px solid #2e99fb', padding: '1rem', textAlign:'center', margin:'0 5rem'}}>
                                        No Script Found
                                    </p>
                                }
                                { (searchResults.length > 0 && total_pages > 1 ) &&
                                    <section className="pagination col-sm-12" >
                                        <div className="col-sm-6 mt-3 ml-3">
                                        </div>
                                        <div className="col-sm-6">
                                        <ReactPaginate
                                            previousLabel={'<<'}
                                            nextLabel={'>>'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={paginate}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                        </div>
                                    </section>}
                            </Row>
                        </Container>
                    </section>
                    }
                    {!isLoggedIn &&
                    <section className="cta">
                        <Container className="text-center">
                            <h3 className="text-uppercase">Cv Script Document Warehouse</h3>
                            <p>Easily upload and download scripts to help jumpstart ideas for Objective 1 videos.</p>
                            <div className="buttons-group">
                                {/* <Button onClick={() => this.props.history.push('/register')} variant="outline-primary" className="mr-5">Sign Up</Button> */}
                                <Button onClick={() => this.props.history.push('/login')} variant="outline-primary">Login</Button>
                            </div>
                        </Container>
                    </section>}
                    <footer>
                        <Container className="text-center">
                            <p>CV Script Storage and Document Warehouse ~ 2021 - {new Date().getFullYear()}</p>
                        </Container>
                    </footer>
                </>
            )
        }else{
            return (
                this.step_build()
            )
        }
    }
}

const mapStateToProps = state => {
    const { script } = state
    return {
        searchResponse: script.scriptSearchResponse,
        loadVideosResponse: script.loadVideosResponse
    }
}

const disptachActions = { recentScriptSearch, loadScriptVideos }

export default connect(mapStateToProps, disptachActions)(LandingPage);