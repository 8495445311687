import { LOGIN, LOGOUT, RESET_PASSWORD, USER_REGISTER } from './actionTypes';
import { fetchRequest } from './apiRequest';
import { toast } from "react-toastify";
toast.configure()
export const login = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const user = await fetchRequest( "/user/authenticate", requestOptions );
        if(user.success && user.token){
            localStorage.setItem('scriptDBToken', JSON.stringify(user.token));
        }
        await dispatch({ type: LOGIN, payload: user })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}

export const logout = () => async dispatch => {
    // remove user from local storage to log user out
    localStorage.clear()
    dispatch({type:LOGOUT})
    window.location.replace('/login')
}

export const register =  data => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( "/user/register", requestOptions );
        await dispatch({ type: USER_REGISTER, payload: result})
    }catch(error){
        // console.log(error)
        toast.dismiss()
        toast.error('Something Went Wrong, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}

export const resetPassword = data => async dispatch => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const result = await fetchRequest( "/user/reset/password", requestOptions );
        await dispatch({ type: RESET_PASSWORD, payload: result})
    }catch(error){
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}

export const socialLogin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const user = await fetchRequest( "/user/ssoLogin", requestOptions );
        // console.log('in action file', user)
        if(user.success && user.token){
            localStorage.setItem('scriptDBToken', JSON.stringify(user.token));
        }
        await dispatch({ type: LOGIN, payload: user });
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}