import { LIST_THEMES, LIST_SUB_THEMES } from './actionTypes';
import { fetchRequest, checkTokenExpire } from './apiRequest';
import authHeader from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()


export const getThemes = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await fetchRequest(`/all-themes`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: LIST_THEMES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getSubThemes = (subThemeId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await fetchRequest(`/sub-themes/${subThemeId}`, requestOptions);
        checkTokenExpire(result)
        await dispatch({ type: LIST_SUB_THEMES, payload: result })
    } catch (error) {
        // console.log("Error ", error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}