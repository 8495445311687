import axios from 'axios';
import { ENV } from '../env';
import authHeader  from '../helpers/auth-header';
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
var SocketURL = process.env.REACT_APP_SERVER_PATH;
toast.configure()

export const fetchRequest = async ( url, httpOptions ) => {
    try {
        const response = await fetch( `${ENV.mainApi + url}`, httpOptions);
        const result = await response.json();
        return result;
    } catch (e) {
        throw e.response    }
}

export const postImageAndFile = async (url, formData) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data; charset=utf-8;",
                'Authorization': authHeader()
            },
        }
        const response = await axios.post( `${ENV.mainApi + url}`, formData, config);
        const result = await response.data;
        return result;
    } catch (e) {
        throw e.response
    }
}


export const checkTokenExpire = (result) => {
    if(result && result.error && result.error.name === 'TokenExpiredError'){
        toast.dismiss()
        toast.error("Session Time Out", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        localStorage.clear();
        setTimeout(() => {
            window.location.replace('/login')
        }, 1000)
    }
}

export const socket = openSocket(SocketURL, {transports:['polling']}); 